import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import { StudentData, CsvValidationError } from '@/types/student-data';
import { Trans, useTranslation } from 'react-i18next';
import classNames from './CsvDropzone.module.scss';
import classnames from 'classnames';
import uploadIcon from '@/assets/svg/upload.svg';
import checkIcon from '@/assets/svg/check.svg';
import closeIcon from '@/assets/svg/closeFill.svg';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import SVG from 'react-inlinesvg';
import ValidateStudent from '@/utils/studentDataValidationUtils';
import AppLoaderCircle from '@/components/AppLoaderCircle';

function fileValidator(file: File): CsvValidationError | null {
    const validTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ];
    const maxSize = 256 * 1024; // 256 KB in bytes

    if (!validTypes.includes(file.type)) {
        return {
            code: "wrong_format",
            message: "",
        };
    }

    if (file.size > maxSize) {
        return {
            code: "file_too_large",
            message: "",
        };
    }

    return null;
}

const validateRowContent = (rows: StudentData[]): { isValid: boolean, reason?: string } => {
    if (rows.length === 0) {
        return { isValid: false, reason: "file_empty" };
    }
    if (rows.length > 50) {
        return { isValid: false, reason: "limit_reached" };
    }
    for (let row of rows) {
        const validationErrors = ValidateStudent(row);
        if (validationErrors.length > 0) {
            return { isValid: false, reason: "bad_formatting" };
        }
    }
    return { isValid: true };
};

interface CsvDropzoneProps {
    studentsData: StudentData[];
    setStudentsData: React.Dispatch<React.SetStateAction<StudentData[]>>;
    isCsvLoading: boolean;
    setIsCsvLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setCsvFilesAdded: React.Dispatch<React.SetStateAction<boolean>>;
}


const CsvDropzone: React.FC<CsvDropzoneProps> = ({ studentsData, setStudentsData, isCsvLoading, setIsCsvLoading, setCsvFilesAdded }) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<File[]>([]);
    const [fileRejections, setFileRejections] = useState<FileRejection[]>([]);
    const [parseError, setParseError] = useState<CsvValidationError | null>(null);

    const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
        setIsCsvLoading(true);
        setCsvFilesAdded(true);
        setFiles(acceptedFiles);
        setFileRejections(fileRejections);
        setTimeout(() => {
            setIsCsvLoading(false);
        }, 2000);
    }, []);

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        maxFiles: 1,
        validator: fileValidator,
        onDrop
    });

    useEffect(() => {
        if (files.length > 0) {
          const file = files[0];
          const fileType = file.name.split('.').pop()?.toLowerCase();
    
          if (fileType === 'csv') {
            parseCSV(file);
          } else if (['xls', 'xlsx'].includes(fileType || '')) {
            parseXLSX(file);
          } else {
            setParseError({
              code: 'wrong_format',
              message: 'wrong_format',
            });
            setStudentsData([]);
          }
        }
      }, [files]);

      const parseCSV = (file: File) => {
        Papa.parse(file, {
          complete: (result) => handleParseComplete(result.data),
          header: true,
          skipEmptyLines: true,
          error: (error) => setParseError({ code: 'bad_formatting', message: error.message }),
        });
      };
    
      const parseXLSX = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            if(e?.target?.result) {
                const data = new Uint8Array(e.target.result as ArrayBuffer);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);
                handleParseComplete(jsonData);
            }
        };
    
        reader.readAsArrayBuffer(file);
      };
    
      const handleParseComplete = (data: any) => {
        const validationResult = validateRowContent(data);
    
        if (validationResult.isValid) {
          setStudentsData(data);
          setParseError(null);
        } else {
          let errorMessage;
          
          switch (validationResult.reason) {
            case 'file_empty':
              errorMessage = 'file_empty';
              break;
            case 'limit_reached':
              errorMessage = 'limit_reached';
              break;
            default:
              errorMessage = 'bad_formatting';
          }
    
          setParseError({
            code: validationResult.reason || '',
            message: errorMessage,
          });
    
          setStudentsData([]);
        }
      };

    const removeFile = useCallback(() => {
        setFiles([]);
        setFileRejections([]);
        setStudentsData([]);
        setParseError(null);
        setCsvFilesAdded(false);
    }, []);

    return (
        <div className="container">
            <div {...getRootProps({ className: classNames.dropzone })}>
                <input {...getInputProps()} />
                {files.length > 0 && !parseError && !isCsvLoading && (
                    <div className={classNames.inner}>
                        <SVG src={checkIcon} className={`${classNames.checkmark} ${classNames.success}`} />
                        <span className={classNames.uploadText}>{t('settings.add_students.upload_successful')}</span>
                        <span className={classNames.badge}>
                            {files[0].name} <SVG src={closeIcon} onClick={removeFile} className={classNames.removeFile} />
                        </span>
                    </div>
                )}

                {parseError && !isCsvLoading && (
                    <div className={classNames.inner}>
                        <SVG src={closeIcon} className={`${classNames.checkmark} ${classNames.failure}`} />
                        <span className={classNames.uploadText}>
                            <Trans i18nKey={`settings.add_students.errors.${parseError.code}`}></Trans>
                        </span>
                        <span className={classNames.badge}>
                            {files.length > 0 ? files[0].name : ''} <SVG src={closeIcon} onClick={removeFile} className={classNames.removeFile} />
                        </span>
                    </div>
                )}

                {fileRejections.length > 0 && !isCsvLoading && (
                    <div className={classNames.inner}>
                        <SVG src={closeIcon} className={`${classNames.checkmark} ${classNames.failure}`} />
                        {fileRejections.length > 0 && fileRejections[0].errors.length > 0 && (
                            <span className={classNames.uploadText}>
                                <Trans i18nKey={`settings.add_students.errors.${fileRejections[0].errors[0].code}`}></Trans>
                            </span>
                        )}
                        <span className={classNames.badge}>
                            {fileRejections[0].file.name} <SVG src={closeIcon} onClick={removeFile} className={classNames.removeFile} />
                        </span>
                    </div>
                )}

                {files.length === 0 && fileRejections.length === 0 && !isCsvLoading && (
                    <div className={classNames.inner}>
                        <SVG src={uploadIcon} />
                        <span className={classNames.uploadText}>{t('settings.add_students.drag_here')}<button type="button" onClick={open} className={classnames(classNames.link, 'browse-files-button')}>{t('settings.add_students.click_to_browse')}</button></span>
                    </div>
                )}

                {isCsvLoading && (
                    <div className={classNames.inner}>
                        <AppLoaderCircle className="m-auto" />
                    </div>
                )}
            </div>
        </div>
    );
}

export default CsvDropzone;
