import { GameTypeProps, MultiChoiceProps } from '@/types/game';
import UseScreenSize from '@/hooks/UseScreenSize';
import classNames from './MultiChoice.module.scss';
import classes from 'classnames';
import commonUtils from '@/utils/common';
import { useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';

import MultiChoiceQuestion from './MultiChoiceQuestion/MultiChoiceQuestion';
import MultiChoiceOptions from './MultiChoiceOptions/MultiChoiceOptions';
import { useDispatch } from 'react-redux';
import UseLocalLang from '@/hooks/UseLocalLang';
import { useTranslation } from 'react-i18next';

const MultiChoice = ({
  currentQuestion,
  options,
  correctAnswer,
  pickedAnswer,
  showAnswerInOptions,
  emitOnAnswer,
  emitOnNextQuestion,
  emitOnRetry,
}: GameTypeProps & MultiChoiceProps) => {
  const localLang = UseLocalLang();
  const { isDesktop } = UseScreenSize();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const {
    selectedType,
    selectedQuiz,
    showOverlay,
    revealWord,
    isLevelTest,
    isInEndOfSkillTest,
    isFinalAssessment,
  } = useAppSelector(gameSelector);

  const onPickOption = (option: string) => {
    if (showOverlay === true) {
      return;
    }

    emitOnAnswer(option);
  };

  const handleRetry = () => {
    dispatch(gameActions.setPictureOverlay(false));
    dispatch(gameActions.resetPickedAnswer());

    emitOnRetry?.();
  };

  return (
    <div
      className={classes(classNames.multiChoice, {
        [classNames.multiChoiceFinalAssessment]: isFinalAssessment,
      })}
    >
      {isDesktop && selectedQuiz && (
        <div className={classNames.instructionsContainer}>
          {isLevelTest && (
            <h2 className={classNames.testYourEnglishText}>
              {t('testYourEnglish')}
            </h2>
          )}
          <h2
            className={classes({
              [classNames['instructions-ltr']]: isFinalAssessment,
            })}
          >
            {commonUtils.getQuizInstructions(selectedQuiz, localLang)}
          </h2>
        </div>
      )}
      {revealWord && (
        <div className={classNames.revealWord}>{currentQuestion.question}</div>
      )}

      <MultiChoiceQuestion
        question={currentQuestion}
        selectedType={selectedType}
        handleRetry={handleRetry}
        emitOnNextQuestion={emitOnNextQuestion}
      />
      <MultiChoiceOptions
        pickedAnswer={pickedAnswer ?? ''}
        showAnswerInOptions={showAnswerInOptions}
        correctAnswer={correctAnswer}
        coins={currentQuestion.coins}
        options={options}
        onSelect={onPickOption}
      />
    </div>
  );
};

export default MultiChoice;
