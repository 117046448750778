import useGroupDetails from '@/hooks/UseGroupDetails';
import { useAppDispatch, useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import {
  teacherDashboardActions,
  teacherDashboardSelector,
} from '@/store/reducers/teacher-dashboard';
import { ClassConfig, TeacherGroup } from '@/types/user';
import { StudentData } from '@/types/student-data';
import {
  CreateCsvStudentsResponse,
  GroupStudentsResponse,
} from '@/types/response';
import { registerService } from '@/services/register';
import { group } from 'console';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDrawer } from '@/context/DrawerContext';
dayjs.extend(utc);

const UseMyClasses = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isAddNewStudentDrawerOpen } = useDrawer();
  const { user } = useAppSelector(authSelector);
  const { isNew, selectedClassId } = useAppSelector(teacherDashboardSelector);
  const groupDetails = useGroupDetails();
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState('*');
  const [classConfig, setClassConfig] = useState<ClassConfig | null>(null);
  const [showClassCreatedModal, setShowClassCreatedModal] = useState(false);
  const [showUploadCsvModal, setShowUploadCsvModal] = useState(false);
  const [showClassLimitModal, setShowClassLimitModal] = useState(false);
  const [showStudentsTableModal, setShowStudentsTableModal] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [isCsvSending, setIsCsvSending] = useState(false);
  const [csvFilesAdded, setCsvFilesAdded] = useState<boolean>(false);

  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [studentsData, setStudentsData] = useState<StudentData[]>([]);
  const [studentsDataResponse, setStudentsDataResponse] =
    useState<CreateCsvStudentsResponse | null>(null);
  const [classStudentsDataResponse, setClassStudentsDataResponse] =
    useState<GroupStudentsResponse | null>(null);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [showNewStudentInput, setShowNewStudentInput] = useState(false);

  const allClassrooms = useMemo(
    () => (user?.teacherGroups ? [...user.teacherGroups] : []),
    [user?.teacherGroups]
  );
  const filterdClassrooms = useMemo(
    () =>
      allClassrooms.filter((i) =>
        selectedFilter === '*'
          ? true
          : selectedFilter === parseInt(i.grade).toString()
      ),
    [allClassrooms, selectedFilter]
  );

  const onFilter = (filter: string) => {
    setSelectedFilter(filter);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  const onShowCopyModal = () => {
    scrollToTop();
    document.body.style.overflow = 'hidden';
    setShowCopyModal(true);
  };

  const onShowCancelModal = () => {
    scrollToTop();
    document.body.style.overflow = 'hidden';
    setShowCancelModal(true);
  };

  const onCloseCancelModal = () => {
    scrollToTop();
    setShowCancelModal(false);
    navigate('/dashboard/my-students');
    document.body.style.overflow = 'auto';
  };

  const onContinue = () => {
    setShowCancelModal(false);
    document.body.style.overflow = 'auto';
  };

  const onCloseCopyModal = () => {
    document.body.style.overflow = 'auto';
    setShowCopyModal(false);
  };

  const onClassCreatedModalShow = () => {
    scrollToTop();
    document.body.style.overflow = 'hidden';
    setShowUploadCsvModal(true);
  };

  const onCloseClassCreatedModal = () => {
    document.body.style.overflow = 'auto';
    setShowClassCreatedModal(false);
  };

  const onShowUploadCsvModal = () => {
    scrollToTop();
    document.body.style.overflow = 'hidden';
    setShowUploadCsvModal(true);
  };

  const onCloseUploadCsvModal = () => {
    document.body.style.overflow = 'auto';
    setShowUploadCsvModal(false);
    setCsvFilesAdded(false);
  };

  const onShowStudentsTableModal = () => {
    scrollToTop();
    document.body.style.overflow = 'hidden';
    setShowUploadCsvModal(false);
    setShowStudentsTableModal(true);
  };

  const onCloseStudentsTableModal = () => {
    document.body.style.overflow = 'auto';
    setShowStudentsTableModal(false);
    setStudentsData([]);
    setStudentsDataResponse(null);
    setShowClassCreatedModal(true);
  };

  const onShowClassLimitModal = () => {
    scrollToTop();
    document.body.style.overflow = 'hidden';
    setShowClassLimitModal(true);
  };

  const onCloseClassLimitModal = () => {
    document.body.style.overflow = 'auto';
    setShowClassLimitModal(false);
  };

  const onBackStudentsTableModal = () => {
    document.body.style.overflow = 'auto';
    setShowStudentsTableModal(false);
    setShowUploadCsvModal(true);
  };

  const createOrSaveClass = async (newConfig: ClassConfig) => {
    // Update group
    if (classConfig?.id) {
      try {
        await groupDetails.saveGroupDetails(classConfig.id, {
          allowIndividualAdvancement: newConfig?.individualAdvancement ?? false,
          groupName: newConfig.name ?? '',
          grade: newConfig.grade ?? '',
          finalAssessment: {
            isActivated: newConfig.finalAssessment ?? false,
            date: dayjs(newConfig.finalAssessmentDate?.toDate())
              .utcOffset(0, true)
              .format('YYYY-MM-DD'),
          },
        });

        setClassConfig((prev) => {
          return {
            ...prev,
            name: newConfig?.name,
            grade: newConfig?.grade,
            individualAdvancement: newConfig?.individualAdvancement ?? false,
            finalAssessment: newConfig.finalAssessment ?? false,
            finalAssessmentDate: newConfig.finalAssessmentDate,
          };
        });

        scrollToTop();
      } catch (e) {
        console.log(e);
      }
    } else {
      // create new group
      try {
        const newGroup = await groupDetails.createGroup({
          allowIndividualAdvancement: newConfig?.individualAdvancement ?? false,
          initalLevelTest: newConfig?.levelTest ?? false,
          groupName: newConfig.name ?? '',
          grade: newConfig.grade ?? '',
          finalAssessment: {
            isActivated: newConfig?.finalAssessment ?? false,
            date: dayjs(newConfig.finalAssessmentDate?.toDate())
              .utcOffset(0, true)
              .format('YYYY-MM-DD'),
          },
        });

        dispatch(teacherDashboardActions.onEdit(newGroup?.groupId ?? ''));

        setClassConfig((prev) => {
          return {
            ...prev,
            id: newGroup?.groupId,
            name: newConfig?.name,
            grade: newConfig?.grade,
            finalAssessment: newConfig?.finalAssessment ?? false,
            finalAssessmentDate: newConfig?.finalAssessmentDate,
            individualAdvancement: newConfig?.individualAdvancement ?? false,
            invitationLinkForStudents: newGroup?.invitationLink ?? '',
            levelTest: newConfig?.levelTest ?? false,
          };
        });

        onShowUploadCsvModal();
      } catch (e) {}
    }
  };

  const onCreateClass = () => {
    setClassConfig(() => {
      const classConfigObj = {
        id: '',
        name: '',
        grade: '',
        groupLevel: '',
        invitationLinkForStudents: '',
        levelTest: true,
        individualAdvancement: true,
        finalAssessment: false,
      } as ClassConfig;

      return classConfigObj;
    });

    if (!isNew) {
      dispatch(teacherDashboardActions.onAdd());
    }
  };

  const onEditClass = async (classroom: TeacherGroup) => {
    setClassConfig({ id: classroom.id });

    const classDetails =
      groupDetails.groups[classroom.id] ??
      (await groupDetails.fetchGroupDetails(classroom.id))?.groupData;

    setClassConfig(() => {
      const { id, name, grade, groupLevel, invitationLinkForStudents } =
        classroom;

      const classConfigObj = {
        id,
        name,
        grade,
        groupLevel,
        invitationLinkForStudents,
        levelTest: classDetails.initalLevelTest ?? false,
        individualAdvancement: classDetails.allowIndividualAdvancement ?? false,
        finalAssessment: classDetails.finalAssessment?.isActivated ?? false,
        finalAssessmentDate: classDetails.finalAssessment?.date,
      } as ClassConfig;

      return classConfigObj;
    });

    if (!selectedClassId) {
      dispatch(teacherDashboardActions.onEdit(classroom.id));
    }
  };

  const onCsvStudentsCreate = async () => {
    setIsCsvSending(true);
    try {
      const createCsvStudentsResponse = await registerService.createCsvStudents(
        user,
        classConfig?.id,
        studentsData
      );
      dispatch(
        authActions.updatedClassroomStudentsAmount({
          id: classConfig?.id ?? '',
          amount:
            createCsvStudentsResponse?.data.data.registeredStudents.length ?? 0,
        })
      );
      setStudentsDataResponse(createCsvStudentsResponse);
      setStudentsData([]);
      setCsvFilesAdded(false);
    } catch (err) {
      console.error('Error creating CSV students:', err);
    } finally {
      setIsCsvSending(false);
      fetchStudentsData();
    }
  };

  const fetchStudentsData = async () => {
    if (user?.id && classConfig?.id) {
      try {
        const studentsResponse = await registerService.getGroupStudents(
          user,
          user?.id,
          classConfig?.id
        );
        setClassStudentsDataResponse(studentsResponse);
      } catch (err) {
        console.error('Error fetching students:', err);
      }
    }
  };

  const selectedFilterText = selectedFilter === '*' ? t('all') : selectedFilter;

  useEffect(() => {
    if (isNew) {
      onCreateClass();
    } else if (user?.teacherGroups?.length) {
      const classToEdit = selectedClassId
        ? user.teacherGroups.find((group) => group.id === selectedClassId)
        : user.teacherGroups[0];

      if (classToEdit) {
        onEditClass(classToEdit);
      }
    }
  }, [user]);

  useEffect(() => {
    if (classConfig?.id) {
      fetchStudentsData();
    } else {
      setStudentsDataResponse(null);
    }
  }, [classConfig?.id, isAddNewStudentDrawerOpen]);

  return {
    selectedFilter,
    selectedFilterText,
    allClassrooms,
    filterdClassrooms,
    classConfig,
    onCreateClass,
    createOrSaveClass,
    onFilter,
    onEditClass,
    showClassCreatedModal,
    onCloseClassCreatedModal,
    showCopyModal,
    setShowCopyModal,
    onCloseCopyModal,
    onCloseCancelModal,
    onShowCopyModal,
    showCancelModal,
    setShowCancelModal,
    onContinue,
    onShowCancelModal,
    user,
    loadingGroupDetails: groupDetails.isLoadingGroups,
    isSavingGroup: groupDetails.isSavingGroup,
    isCsvLoading,
    isCsvSending,
    setIsCsvLoading,
    csvFilesAdded,
    setCsvFilesAdded,
    showUploadCsvModal,
    showStudentsTableModal,
    onCloseUploadCsvModal,
    onShowStudentsTableModal,
    onCloseStudentsTableModal,
    onBackStudentsTableModal,
    studentsData,
    setStudentsData,
    onCsvStudentsCreate,
    studentsDataResponse,
    disableSubmitButton,
    setDisableSubmitButton,
    showNewStudentInput,
    setShowNewStudentInput,
    classStudentsDataResponse,
    showClassLimitModal,
    onShowClassLimitModal,
    onCloseClassLimitModal,
  };
};

export default UseMyClasses;
