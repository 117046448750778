import classNames from './PopUpSummary.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store';
import { GameTypes, PopUpSummaryProps } from '@/types/game';
import { useState } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';
import { gameSelector } from '@/store/reducers/game';

import QuizSummary from './QuizSummary/QuizSummary';
import PracticeSummary from './PracticeSummary/PracticeSummary';

const PopUpSummary = ({
  className,
  selectedSkill,
  selectedTopic,
  selectedQuiz,
  coins,
  tip,
  userData,
  correctAnswers,
  totalQuestions,
  hasNextQuiz,
  emitOnClosePopUp,
  emitOnExit,
  emitOnAknowledge,
  emitStartQuizFromPractice,
  emitPracticeAgain,
  emitOnQuit,
  emitOnEndOfSkillTest,
}: PopUpSummaryProps) => {
  const { t } = useTranslation();
  const [shouldExpand, setShouldExpand] = useState(false);
  const { isMobile } = UseScreenSize();
  const { gameType } = useAppSelector(gameSelector);

  const isPractice = gameType === GameTypes.Practice;

  return (
    <div
      className={classes(
        {
          container: isMobile,
        },
        className,
        classNames.popUpSummary
      )}
    >
      {shouldExpand && isMobile && <div className={classNames.backdrop}></div>}
      {isMobile && (
        <div className={classNames.exitBtn}>
          <button onClick={emitOnExit}>{t('exit')}</button>
        </div>
      )}
      {isPractice ? (
        <PracticeSummary
          emitOnAknowledge={emitOnAknowledge}
          emitStartQuizFromPractice={emitStartQuizFromPractice}
          emitPracticeAgain={emitPracticeAgain}
          emitOnQuit={emitOnQuit}
        />
      ) : (
        <QuizSummary
          selectedTopic={selectedTopic}
          selectedQuiz={selectedQuiz}
          selectedSkill={selectedSkill}
          coins={coins}
          tip={tip}
          hasNextQuiz={hasNextQuiz}
          userData={userData}
          correctAnswers={correctAnswers}
          totalQuestions={totalQuestions}
          shouldExpand={shouldExpand}
          emitShouldExpand={(payload) => setShouldExpand(payload)}
          emitOnClosePopUp={emitOnClosePopUp}
          emitOnEndOfSkillTest={emitOnEndOfSkillTest}
          emitOnExit={emitOnExit}
        />
      )}
    </div>
  );
};

export default PopUpSummary;
