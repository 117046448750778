import { GameTypeProps, MultiChoiceProps } from '@/types/game';
import classNames from './MultiChoiceOptions.module.scss';
import classes from 'classnames';
import SVG from 'react-inlinesvg';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';

import AnswerReward from '../../AnswerReward/AnswerReward';

import correct from '@/assets/svg/correct-circle.svg';
import wrong from '@/assets/svg/wrong-circle.svg';
import { SkillTypes } from '@/types/skill';
import { useMemo } from 'react';
import {
  authSelector,
  interfaceLanguageSelector,
  nativeLanguageSelector,
} from '@/store/reducers/auth';
import { TopicTypes } from '@/types/topic';
import UserUtils from '@/utils/user';

type MultiChoiceOptionsProps = {
  className?: string;
  coins?: number;
  correctAnswer: GameTypeProps['correctAnswer'];
  pickedAnswer: MultiChoiceProps['pickedAnswer'];
  options: MultiChoiceProps['options'];
  showAnswerInOptions?: MultiChoiceProps['showAnswerInOptions'];
  isDictionary?: boolean;
  onSelect: (answer: string) => void;
};

const MultiChoiceOptions = ({
  className,
  coins,
  showAnswerInOptions,
  onSelect,
  pickedAnswer,
  correctAnswer,
  options,
  isDictionary = false,
}: MultiChoiceOptionsProps) => {
  const {
    selectedType,
    currentQuestionIndex,
    gameQuestions,
    selectedTopic,
    isInEndOfSkillTest,
    isLevelTest,
  } = useAppSelector(gameSelector);

  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const nativeLanguage = useAppSelector(nativeLanguageSelector);

  const { user } = useAppSelector(authSelector);

  const isStudent = UserUtils.isStudent(user);

  const currentQuestion = useMemo(() => {
    return gameQuestions[currentQuestionIndex];
  }, [currentQuestionIndex, gameQuestions]);

  const isRead = selectedType === SkillTypes.Read;

  const optionsElements = options?.map((option, index) => {
    const isOptionSelected =
      option?.toLowerCase() === pickedAnswer?.toLowerCase();

    const isSelectedOptionCorrect =
      pickedAnswer?.toLowerCase() === correctAnswer?.toLowerCase();

    const isAnswer =
      showAnswerInOptions &&
      !isSelectedOptionCorrect &&
      correctAnswer?.toLowerCase() === option?.toLowerCase();

    const isOptionCorrect = isOptionSelected && isSelectedOptionCorrect;
    const isOptionWrong = isOptionSelected && !isSelectedOptionCorrect;

    const optionClasses = classes(classNames.option, {
      'cursor-pointer': !pickedAnswer,
      [classNames.optionCorrect]: isOptionCorrect,
      [classNames.optionWrong]: isOptionWrong,
      [classNames.answer]: isAnswer,
      // [classNames.leftJustify]:
      //   options.length === 3 &&
      //   index === 2 &&
      //   interfaceLanguage?.direction === 'rtl',
    });

    const alphabet = String.fromCharCode(index + 97);

    const statusElement = !isOptionSelected ? (
      <span
        className={classes(classNames.alphabet, {
          [classNames[`alphabet-${selectedType}`]]: !isDictionary,
          [classNames.alphabetAnswer]: isAnswer,
        })}
      >
        {alphabet}
      </span>
    ) : (
      <div className={classNames.answerStatus}>
        <SVG src={isOptionCorrect ? correct : wrong} />
      </div>
    );
    return (
      <div
        key={option}
        className={optionClasses}
        onClick={() => (pickedAnswer ? null : onSelect(option))}
      >
        <div className={classNames.details}>
          {statusElement}
          <span
            className={classes(classNames.text, [
              classNames[`text-${nativeLanguage?.direction}`],
            ])}
          >
            {option}
          </span>
        </div>
        {isStudent &&
          !currentQuestion.userData?.achievedCoins &&
          !isInEndOfSkillTest &&
          isOptionCorrect &&
          !isLevelTest &&
          coins && (
            <AnswerReward className={classNames.answerReward} coins={coins} />
          )}
      </div>
    );
  });

  return (
    <div
      className={classes(
        classNames.multiChoiceOptions,
        {
          [classNames.multiChoiceOptionsDictionary]: isDictionary,
          [classNames.multiChoiceOptionsRead]: isRead,
          [classNames.isInEndOfSkillTestRead]: isInEndOfSkillTest && isRead,
        },
        className
      )}
    >
      {optionsElements}
    </div>
  );
};

export default MultiChoiceOptions;
