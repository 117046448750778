import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/types/common';
import {
  AknowledgeWordPayload,
  GameState,
  GameStatus,
  GameTypes,
  PickAnswerPayload,
  StartGamePayload,
  LevelTestQuestionResult,
  QuizQuestionResult,
  HintsType,
  Block,
  levelTestQuizResultData,
} from '@/types/game';
import { SkillSettingsItem, UserData } from '@/types/user';
import { Topic } from '@/types/topic';

import { SkillTypes } from '@/types/skill';
import GameUtils from '@/utils/gameUtils';
import { stat } from 'fs';
import { Question } from '@/types/question';
import { Quiz } from '@/types/quiz';
import ReactGA from 'react-ga4';
import { EventNames } from '@/types/analytics';
import commonUtils from '@/utils/common';

const defaultGameState: GameState = {
  fromToLang: null,
  checkAnswerCompleted: false,
  maxSpellingLetters: 12,
  selectedType: null,
  selectedQuiz: null,
  selectedTopic: null,
  selectedSkill: null,
  gameStatus: GameStatus.PREPARING,
  prevStatus: null,
  currentTime: 0,
  responseTimer: Date.now(),
  totalQuizTime: new Date(),
  hasTimer: true,
  isMuted: false,
  gameType: null,
  currentQuestionIndex: 0,
  currentReadQuestionIndex: 0,
  correctAnswers: 0,
  total_correctAnswers: 0,
  total_inCorrectAnswers: 0,
  inCorrectAnswers: 0,
  gameQuestions: [],
  pickedAnswer: null,
  coins: 0,
  achievedStars: 0,
  focusShown: false,
  keepGoingShown: false,
  timesUpShown: false,
  selectedHint: null,
  allowRetry: true,
  showPreQuiz: false,
  showAnswerInOptions: false,
  practiceCards: [],
  questionResultData: [],
  quizQuestionResultData: [],
  levelTestQuizResultData: {},
  showOverlay: false,
  visitFocus: false,
  visitKeepGoing: false,
  possibleAnswers: [],
  showLetter: false,
  removeLetter: false,
  revealTranslation: false,
  revealWord: false,
  hintsData: [],
  soundWaveIsReady: null,
  isLevelTest: false,
  isInEndOfSkillTest: false,
  isFinalAssessment: false,
};

const defaultTimer = 30;

const game = createSlice({
  name: 'Game',
  initialState: defaultGameState,
  reducers: {
    setSelectedSkill: (
      state,
      action: PayloadAction<{
        type: SkillTypes | null;
        skill?: SkillSettingsItem | null;
      }>
    ) => {
      state.selectedSkill = action.payload.skill;
      state.selectedType = action.payload.type;
    },
    setSelectedTopic: (state, action: PayloadAction<Topic | null>) => {
      state.selectedTopic = action.payload;
    },
    setInEndOfSkillTest: (state, action: PayloadAction<boolean>) => {
      state.isInEndOfSkillTest = action.payload;
    },
    setIsFinalAssessmentTest: (state, action: PayloadAction<boolean>) => {
      state.isFinalAssessment = action.payload;
    },
    setSelectedQuiz: (state, action: PayloadAction<Quiz | null>) => {
      state.selectedQuiz = action.payload;
    },
    resetGame: (state) => {
      if (state.isLevelTest || state.isFinalAssessment) {
        state.selectedQuiz = null;
      }
      state.revealTranslation = false;
      state.revealWord = false;
      state.selectedHint = null;
      state.soundWaveIsReady = null;
      state.checkAnswerCompleted = false;
      state.visitFocus = false;
      state.visitKeepGoing = false;
      state.pickedAnswer = null;
      state.gameQuestions = [];
      state.achievedStars = 0;
      state.gameStatus = GameStatus.PREPARING;
      state.prevStatus = null;
      state.correctAnswers = 0;
      state.coins = 0;
      state.currentQuestionIndex = 0;
      state.currentTime = 0;
      state.totalQuizTime = new Date();
      state.inCorrectAnswers = 0;
      state.total_correctAnswers = 0;
      state.total_inCorrectAnswers = 0;
      state.selectedHint = null;
      state.allowRetry = true;
      state.showPreQuiz = false;
      state.showAnswerInOptions = false;
      state.focusShown = false;
      state.keepGoingShown = false;
      state.timesUpShown = false;
      state.practiceCards = [];
      state.questionResultData = [];
      state.quizQuestionResultData = [];
      state.showOverlay = false;
      state.currentReadQuestionIndex = 0;
      state.possibleAnswers = GameUtils.getPossibleAnswers(
        state.gameQuestions[state.currentQuestionIndex],
        state.gameType,
        state.selectedType
      );
    },

    setCheckAnswerCompleted: (state, action: PayloadAction<boolean>) => {
      state.checkAnswerCompleted = action.payload;
    },

    resetAllSelected: (state) => {
      state.selectedType = null;
      state.selectedSkill = null;
      state.selectedTopic = null;
    },

    startGame: (state, action: PayloadAction<StartGamePayload>) => {
      /// Track game started event
      ReactGA.event(EventNames.GameStart);

      state.gameType = action.payload.gameType;

      const sortedQuestions = commonUtils.sortByKey(
        [...action.payload.questions],
        'order'
      );

      state.gameQuestions = sortedQuestions;

      state.gameStatus = GameStatus.PLAYING;

      state.hintsData = Array.from(Array(state.gameQuestions.length)).fill({
        hintUsageCount: 0,
        coinsUsed: 0,
      });
      state.currentTime = state.selectedQuiz?.timer ?? defaultTimer;
      state.responseTimer = Date.now();
      state.totalQuizTime = new Date();
      state.soundWaveIsReady = null;
      state.possibleAnswers = GameUtils.getPossibleAnswers(
        state.gameQuestions[state.currentQuestionIndex],
        state.gameType,
        state.selectedType
      );
    },

    endGame: (state) => {
      /// Track game completed event
      ReactGA.event(EventNames.GameComplete);

      state.gameStatus = GameStatus.ENDED;
      state.currentTime = 0;
    },

    timeOutGame: (state) => {
      state.currentTime = 0;
      state.gameStatus = GameStatus.TIME_ENDED;
    },
    setQuestionIndex: (state, { payload }: PayloadAction<number>) => {
      if (state.gameStatus === GameStatus.TIME_ENDED && !state.allowRetry) {
        const questionResultData: QuizQuestionResult = {
          questionId: state.gameQuestions[payload].questionId,
          isCorrect: false,
          isTimesUp: true,
          wrongAnswer: '',
          responseTime: GameUtils.getSecondsBetweenDates(
            state.responseTimer,
            Date.now()
          ),
          achievedCoins: 0,
          coinsUsed: state.hintsData[payload].coinsUsed,
          hintUsageCount: state.hintsData[payload].hintUsageCount,
        };
        state.quizQuestionResultData.push(questionResultData);
      }
      state.gameStatus = GameStatus.PLAYING;
      state.currentQuestionIndex = payload;
      state.pickedAnswer = null;
    },
    setIsLevelTest: (state, action: { payload: boolean }) => {
      state.isLevelTest = action.payload;
    },
    goToNextQuestion: (
      state,
      {
        payload,
      }: PayloadAction<{
        quizzesLength?: number;
        levelTestQuizIndex?: number;
        isSimple?: boolean;
      }>
    ) => {
      const { quizzesLength, levelTestQuizIndex, isSimple } = payload;

      const isRead = state.selectedType === SkillTypes.Read;

      const isNotLastQuestion =
        quizzesLength &&
        levelTestQuizIndex &&
        isRead &&
        levelTestQuizIndex !== quizzesLength - 1;

      const isNotLastReadQuestion =
        isRead &&
        state.isLevelTest &&
        state.currentReadQuestionIndex < state.gameQuestions.length - 1;

      if (isNotLastReadQuestion) {
        state.currentReadQuestionIndex = state.currentReadQuestionIndex + 1;

        return;
      } else if (isNotLastQuestion) {
        state.currentReadQuestionIndex = 0;
      }

      if (
        state.currentQuestionIndex >= state.gameQuestions.length - 1 &&
        !state.isLevelTest &&
        !state.isInEndOfSkillTest &&
        !state.isFinalAssessment
      ) {
        if (!isRead) {
          state.gameStatus = GameStatus.ENDED;
          state.currentTime = 0;
        }

        return;
      }

      if (state.gameStatus === GameStatus.TIME_ENDED && !state.allowRetry) {
        const questionResultData: QuizQuestionResult = {
          questionId:
            state.gameQuestions[state.currentQuestionIndex].questionId,
          isCorrect: false,
          isTimesUp: true,
          wrongAnswer: '',
          responseTime: 0,
          achievedCoins: 0,
          coinsUsed: state.hintsData[state.currentQuestionIndex].coinsUsed,
          hintUsageCount:
            state.hintsData[state.currentQuestionIndex].hintUsageCount,
        };
        state.quizQuestionResultData.push(questionResultData);
      }

      if (state.isInEndOfSkillTest || state.isFinalAssessment) {
        if (state.gameQuestions.length === state.currentQuestionIndex + 1) {
          state.gameStatus = GameStatus.ENDED;
          return;
        }
      }

      let nextQuestionIndex = state.isLevelTest
        ? 0
        : state.currentQuestionIndex + 1;

      if (!isSimple && isRead && !state.isLevelTest) {
        const unansweredReadQuestion = state.gameQuestions.find(
          (question) => !question.pickedAnswer
        );

        const unansweredReadQuestionIndex = state.gameQuestions.findIndex(
          (question) =>
            question.questionId === unansweredReadQuestion?.questionId
        );

        nextQuestionIndex =
          unansweredReadQuestionIndex < 0
            ? state.currentQuestionIndex
            : unansweredReadQuestionIndex;
      }

      state.gameStatus = state.isLevelTest
        ? GameStatus.ENDED
        : GameStatus.PLAYING;
      state.currentQuestionIndex = nextQuestionIndex;
      state.allowRetry = true;
      state.timesUpShown = false;
      state.possibleAnswers = GameUtils.getPossibleAnswers(
        state.gameQuestions[state.currentQuestionIndex],
        state.gameType,
        state.selectedType
      );
      state.pickedAnswer = null;

      if (!isSimple) {
        state.showOverlay = false;
        state.checkAnswerCompleted = false;
        state.soundWaveIsReady = null;
        state.showLetter = false;
        state.revealWord = false;
        state.revealTranslation = false;
      }

      if (!isRead) {
        state.currentTime = state.selectedQuiz?.timer ?? defaultTimer;
        state.responseTimer = Date.now();
      }

      state.showAnswerInOptions = false;
    },
    goToPreviousQuestion: (
      state,
      {
        payload,
      }: PayloadAction<{
        isSimple?: boolean;
      }>
    ) => {
      const { isSimple } = payload;

      const isRead = state.selectedType === SkillTypes.Read;

      if (state.showOverlay) {
        return;
      }

      if (!state.currentQuestionIndex) {
        return;
      }

      if (state.gameStatus === GameStatus.TIME_ENDED && !state.allowRetry) {
        const questionResultData: QuizQuestionResult = {
          questionId:
            state.gameQuestions[state.currentQuestionIndex].questionId,
          isCorrect: false,
          isTimesUp: true,
          wrongAnswer: '',
          responseTime: GameUtils.getSecondsBetweenDates(
            state.responseTimer,
            Date.now()
          ),
          achievedCoins: 0,
          coinsUsed: state.hintsData[state.currentQuestionIndex].coinsUsed,
          hintUsageCount:
            state.hintsData[state.currentQuestionIndex].hintUsageCount,
        };

        state.quizQuestionResultData.push(questionResultData);
      }

      state.gameStatus = GameStatus.PLAYING;
      state.currentQuestionIndex = state.currentQuestionIndex - 1;
      state.possibleAnswers = GameUtils.getPossibleAnswers(
        state.gameQuestions[state.currentQuestionIndex],
        state.gameType,
        state.selectedType
      );

      if (!isSimple) {
        state.pickedAnswer = null;
      }

      if (!isRead) {
        state.currentTime = state.selectedQuiz?.timer ?? defaultTimer;
        state.responseTimer = Date.now();
      }

      state.showAnswerInOptions = false;
    },
    updateGameTime: (state) => {
      if (!GameUtils.shouldHandleTimer(state)) {
        return;
      }

      state.currentTime = state.currentTime - 1;
    },
    toggleGameStatus: (state, action: PayloadAction<GameStatus>) => {
      state.selectedHint = null;
      state.gameStatus = action.payload;
    },
    toggleGameMute: (state) => {
      state.isMuted = !state.isMuted;
    },
    onPlay: (state) => {
      if (state.gameStatus === GameStatus.PAUSED_SIMPLE && state.prevStatus) {
        state.gameStatus = state.prevStatus;
        state.prevStatus = null;
        return;
      }

      state.gameStatus = GameStatus.PLAYING;
    },
    onPause: (state, action: PayloadAction<{ simple: boolean }>) => {
      state.prevStatus = state.gameStatus;

      state.gameStatus = action.payload.simple
        ? GameStatus.PAUSED_SIMPLE
        : GameStatus.PAUSED;
    },
    resetTimer: (state) => {
      state.gameStatus = GameStatus.PLAYING;
      state.currentTime = state.selectedQuiz?.timer ?? defaultTimer;
    },
    setCurrentTimer: (state, action: PayloadAction<number>) => {
      state.currentTime = action.payload;
    },
    accumulateCoins: (state, action: PayloadAction<number>) => {
      state.coins = action.payload;
    },
    setPickedAnswer: (state, action: PayloadAction<PickAnswerPayload>) => {
      state.pickedAnswer = action.payload.answer ?? null;

      if (!GameUtils.verifyAnswerByGameType(state) && state.focusShown) {
        state.inCorrectAnswers = state.inCorrectAnswers + 1;
        state.gameStatus = GameStatus.ENDED;
        state.currentTime = 0;
      }
    },

    resetCurrentQuestionState: (state) => {
      state.total_inCorrectAnswers = state.total_inCorrectAnswers - 1;
      state.inCorrectAnswers = state.inCorrectAnswers - 1;
      state.quizQuestionResultData.pop();
      state.visitFocus = false;
    },
    resetLevelTestQuizResultData: (state) => {
      state.levelTestQuizResultData = {};
      state.currentReadQuestionIndex = 0;
      state.total_correctAnswers = 0;
    },
    resetCurrentReadQuestionIndex: (state) => {
      state.currentReadQuestionIndex = 0;
    },

    handlePickedAnswer: (state, action: PayloadAction<PickAnswerPayload>) => {
      const answer = action.payload.answer ?? null;
      state.pickedAnswer = answer;

      const isRead = state.selectedType === SkillTypes.Read;

      if (isRead) {
        if (state.isLevelTest) {
          state.gameQuestions[state.currentReadQuestionIndex].pickedAnswer =
            answer;
        } else {
          state.gameQuestions[state.currentQuestionIndex].pickedAnswer = answer;
        }
      }

      // Let's verify the picked answer by game type
      // There could be various different ways to check for an answer

      const answeredCorrectly: boolean =
        GameUtils.verifyAnswerByGameType(state);
      // if (!answeredCorrectly && state.allowRetry) {
      //   state.showOverlay = true;
      //   return;
      // }

      if (answeredCorrectly) {
        // Distribute the current question coins allowance
        state.correctAnswers = state.correctAnswers + 1;
        state.total_correctAnswers = state.total_correctAnswers + 1;

        if (
          !GameUtils.currentQuestion(state).userData?.achievedCoins &&
          !state.isLevelTest
        ) {
          state.coins =
            state.coins + (GameUtils.currentQuestion(state).coins ?? 0);
        }
      } else {
        state.total_inCorrectAnswers = state.total_inCorrectAnswers + 1;
        state.inCorrectAnswers = state.inCorrectAnswers + 1;

        // if (state.focusShown) {
        // state.gameStatus = GameStatus.ENDED;
        // state.currentTime = 0;
        // }
      }

      if (state.isLevelTest) {
        const questionResultData: LevelTestQuestionResult = {
          questionId:
            state.isLevelTest && isRead
              ? state.gameQuestions[state.currentReadQuestionIndex].questionId
              : state.gameQuestions[state.currentQuestionIndex].questionId,
          isCorrect: answeredCorrectly,
          isTimesUp: false,
          wrongAnswer: answeredCorrectly ? null : action.payload.answer ?? '',
          responseTime: GameUtils.getSecondsBetweenDates(
            state.responseTimer,
            Date.now()
          ),
        };

        if (state.selectedQuiz?.skill) {
          const selectedSkill = state.selectedQuiz.skill;
          const quizResultData: levelTestQuizResultData =
            state.levelTestQuizResultData as levelTestQuizResultData;
          if (!quizResultData[selectedSkill]) {
            quizResultData[selectedSkill] = [];
          }

          quizResultData[selectedSkill].push(questionResultData);
        }
      } else {
        //todo Need to add practice check
        const questionResultData: QuizQuestionResult = {
          questionId:
            state.gameQuestions[state.currentQuestionIndex].questionId,
          isCorrect: answeredCorrectly,
          isTimesUp: false,
          wrongAnswer: answeredCorrectly ? null : action.payload.answer ?? null,
          responseTime: GameUtils.getSecondsBetweenDates(
            state.responseTimer,
            Date.now()
          ),
          achievedCoins: answeredCorrectly
            ? state.gameQuestions[state.currentQuestionIndex].coins ?? 0
            : 0,
          coinsUsed: state.hintsData[state.currentQuestionIndex].coinsUsed,
          hintUsageCount:
            state.hintsData[state.currentQuestionIndex].hintUsageCount,
        };
        state.quizQuestionResultData.push(questionResultData);
      }

      // Set game status to CHECK_ANSWER to prevent futher answering
      // We listen to the game status on our quiz_model.dart and handle all logic there
      // Logic would be:
      // Showing a modal.. etc

      state.gameStatus = GameStatus.CHECK_ANSWER;
    },
    resetIncorrectAnswers: (state) => {
      state.inCorrectAnswers = 0;
    },
    incrementIncorrectAnswers: (state) => {
      state.inCorrectAnswers = state.inCorrectAnswers + 1;
    },
    resetCorrectAnswers: (state) => {
      state.correctAnswers = 0;
    },
    setFocusShown: (state) => {
      state.focusShown = true;
    },
    setKeepGoingShown: (state) => {
      state.keepGoingShown = true;
    },
    setTimesUpShown: (state) => {
      state.timesUpShown = true;
    },
    setSelectedHint: (
      state,
      action: PayloadAction<{ hint: HintsType | null; soundPath: string }>
    ) => {
      /// Track hint used event
      ReactGA.event(EventNames.HintUsed);

      const { hint, soundPath } = action.payload;

      if (hint === null) {
        state.selectedHint = null;
        return;
      }

      const currentHintsData = state.hintsData[state.currentQuestionIndex];

      state.hintsData[state.currentQuestionIndex] = {
        coinsUsed: currentHintsData.coinsUsed + 5,
        hintUsageCount: currentHintsData.hintUsageCount + 1,
      };

      if (hint === HintsType.ADD_TIME) {
        state.currentTime = state.currentTime + 30;
      }
      if (hint === HintsType.SHOW_LETTER) {
        state.showLetter = true;
      }
      if (hint === HintsType.REMOVE_LETTER) {
        state.removeLetter = true;
      }
      if (hint === HintsType.REMOVE_ONE_ANSWER) {
        const currentQuestions =
          state.gameQuestions[state.currentQuestionIndex];

        const answer = currentQuestions.answer;

        const isRead = state.selectedType === SkillTypes.Read;

        let options = isRead
          ? currentQuestions.options ?? []
          : state.possibleAnswers;

        if (options.length <= 2) {
          return;
        }

        const optionsIndex = options
          .map((option, index) => {
            if (option !== answer) {
              return index;
            }
          })
          .filter((option) => option !== undefined) as number[];

        const randomIndexInOptions = Math.floor(
          Math.random() * optionsIndex.length
        );

        const afterCut = options.splice(optionsIndex[randomIndexInOptions], 1);

        if (isRead) {
          currentQuestions.options = (currentQuestions.options ?? []).filter(
            (option) => !afterCut.includes(option)
          );
          return;
        }

        state.possibleAnswers = state.possibleAnswers.filter(
          (option) => !afterCut.includes(option)
        );
      }
      if (hint === HintsType.SEE_TRANSLATION) {
        state.revealTranslation = true;
      }
      if (hint === HintsType.SEE_ENGLISH_WORD) {
        state.revealWord = true;
      }
      if (hint === HintsType.HEAR_ENGLISH_WORD) {
        new Audio(soundPath).play();
      }

      state.selectedHint = hint;
    },
    disableRetry: (state) => {
      state.allowRetry = false;
      state.checkAnswerCompleted = false;
    },
    resetPickedAnswer: (state) => {
      state.pickedAnswer = null;
    },
    setShowPreQuiz: (state, action: PayloadAction<boolean>) => {
      state.showPreQuiz = action.payload;
    },
    setShowAnswerInOptions: (state) => {
      state.showAnswerInOptions = true;
    },
    setHideAnswerInOptions: (state) => {
      state.showAnswerInOptions = false;
    },
    setFromToLang: (state, action: PayloadAction<string>) => {
      state.fromToLang = action.payload;
    },
    setPracticeGame: (state) => {
      state.gameType = GameTypes.Practice;
      state.practiceCards = [...state.gameQuestions];
    },
    onSwipe: (state, action: PayloadAction<number>) => {
      state.practiceCards = state.practiceCards.filter(
        (practiceCard) => practiceCard.questionId !== action.payload
      );
    },
    setWordKnowledge: (state, action: PayloadAction<AknowledgeWordPayload>) => {
      const questionIndex = state.gameQuestions.findIndex(
        (quesiton) => quesiton.questionId === action.payload.id
      );

      if (!state.gameQuestions[questionIndex].userData) {
        state.gameQuestions[questionIndex].userData = {
          practiceMode: null,
        } as UserData;
      }

      (state.gameQuestions[questionIndex].userData as UserData).practiceMode = {
        isKnown: action.payload.isKnown,
      };
    },

    appendQuestionResultData: (
      state,
      action: PayloadAction<LevelTestQuestionResult>
    ) => {
      state.questionResultData = [...state.questionResultData, action.payload];
    },
    setPictureOverlay: (state, action: PayloadAction<boolean>) => {
      state.showOverlay = action.payload;
    },
    toggleShowFocus: (state, action: PayloadAction<boolean>) => {
      state.visitFocus = action.payload;
    },
    toggleSoundWaveStatus: (state, action: PayloadAction<boolean>) => {
      state.soundWaveIsReady = action.payload;
    },
    toggleShowKeepGoing: (state, action: PayloadAction<boolean>) => {
      state.visitKeepGoing = action.payload;
    },
    setShowLetter: (state, action: PayloadAction<boolean>) => {
      state.showLetter = action.payload;
    },
    setRemoveLetter: (state, action: PayloadAction<boolean>) => {
      state.removeLetter = action.payload;
    },
    setRevealTranslation: (state, action: PayloadAction<boolean>) => {
      state.revealTranslation = action.payload;
    },
  },
  extraReducers: {},
});

export const gameActions = game.actions;

export const gameSelector = (state: RootState) => state.game;

export default game.reducer;
