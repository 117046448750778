import React, { useMemo } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';
import { Outlet, useNavigate } from 'react-router-dom';
import UserUtils from '@/utils/user';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import { View } from '@/types/common';
import { ROUTE_NAMES } from '@/router';

import PageWrap from '@/components/PageWrap';
import TheSidebar from '@/components/TheSidebar/TheSidebar';

import studentsIcon from '@/assets/svg/classroom.svg';
import classesIcon from '@/assets/svg/classes.svg';
import dashboardIcon from '@/assets/svg/dashboard.svg';
import homepageIcon from '@/assets/svg/homepage.svg';
import profileIcon from '@/assets/svg/my-profile.svg';

const MainView = () => {
  const { isMobile } = UseScreenSize();
  const navigate = useNavigate();
  const { user } = useAppSelector(authSelector);

  const isTeacher = UserUtils.isTeacher(user);

  const teacherViewTabs = [
    {
      icon: studentsIcon,
      name: ROUTE_NAMES.MY_STUDENTS,
      path: '/dashboard/my-students',
      text: 'settings.my_students',
      order: 1,
    },
    {
      icon: classesIcon,
      name: ROUTE_NAMES.MY_CLASSES,
      path: '/dashboard/my-classes/',
      text: 'settings.my_classes',
      order: 2,
    },
    {
      icon: dashboardIcon,
      name: ROUTE_NAMES.GAMES,
      path: '/dashboard/games',
      text: 'settings.my_games',
      order: 3,
    },
  ];

  const studentViewTabs = [
    {
      icon: homepageIcon,
      name: ROUTE_NAMES.GAMES,
      path: '/dashboard/games',
      text: 'homepage',
      order: 1,
    },
    {
      icon: profileIcon,
      name: ROUTE_NAMES.PROFILE,
      path: '/dashboard/profile',
      text: 'myProfile',
      order: 2,
    },
  ];

  const viewTabs = useMemo<View[]>(() => {
    return isTeacher ? [...teacherViewTabs] : [...studentViewTabs];
  }, []);

  const onSelectView = (view: View) => {
    const teacherLevel = localStorage.getItem('teacher-level');
    if (
      view.name == ROUTE_NAMES.GAMES &&
      isTeacher &&
      !user?.level &&
      (teacherLevel === null || teacherLevel === '')
    ) {
      return navigate('/dashboard/level-test');
    }
    navigate(view.path);
  };

  if (isMobile) {
    return <Outlet />;
  }

  return (
    <div className="flex">
      <TheSidebar viewTabs={viewTabs} emitSelectView={onSelectView} />
      <PageWrap>
        <Outlet />
      </PageWrap>
    </div>
  );
};

export default React.memo(MainView);
