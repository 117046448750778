import classNames from './TheButton.module.scss';
import classes from 'classnames';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import SVG from 'react-inlinesvg';
import { ButtonHTMLAttributes } from 'react';

import rightArrow from '@/assets/svg/right-arrow-large.svg';
import { authSelector, interfaceLanguageSelector } from '@/store/reducers/auth';
import { rtlLangs } from '@/context/LocaleContext';

interface TheButtonProps {
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  text: string;
  description?: string;
  descriptionClassName?: string;
  className?: string;
  plain?: boolean;
  showArrow?: boolean;
  shouldRecolor?: boolean;
  disabled?: boolean;
  allowDynamicDirection?: boolean;
  emitOnClick?: () => void;
}

const TheButton = ({
  type = 'button',
  text,
  description,
  descriptionClassName,
  className,
  plain = false,
  showArrow = false,
  shouldRecolor = true,
  disabled = false,
  allowDynamicDirection = true,
  emitOnClick,
}: TheButtonProps) => {
  const { selectedType } = useAppSelector(gameSelector);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const { user } = useAppSelector(authSelector);

  let isRtl;
  if (user?.interfaceLanguage) {
    isRtl = rtlLangs.includes(user?.interfaceLanguage);
  }

  return (
    <button
      type={type}
      className={classes(
        classNames.theButton,
        {
          [classNames[`theButton-${interfaceLanguage?.direction}`]]:
            allowDynamicDirection,
        },
        { [classNames[`theButton-${selectedType}`]]: !plain && shouldRecolor },
        { [classNames.plain]: plain },
        className
      )}
      onClick={emitOnClick}
      disabled={disabled}
    >
      {description && (
        <span className={descriptionClassName}>{description}</span>
      )}
      <span>{text}</span>
      {showArrow && (
        <SVG
          src={rightArrow}
          className={classes()}
          style={{
            transform: `rotate(${
              isRtl && allowDynamicDirection ? '180deg' : '0deg'
            })`,
          }}
        />
      )}
    </button>
  );
};

export default TheButton;
