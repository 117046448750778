import { createBrowserRouter } from 'react-router-dom';

import App from '@/App';
import ProtectedRoute from '@/components/ProtectedRoute';
import PublicRoute from '@/components/PublicRoute';

import LoginLayout from '@/layouts/Login';
import DashboardLayout from '@/layouts/Dashboard';
import RegistrationLayout from '@/layouts/Registration';
import OnboardingLayout from '@/layouts/Onboarding';
import NotFoundLayout from '@/layouts/NotFound';

import LoginView from '@/pages/login/LoginView';

import RegistrationView from '@/pages/registration/RegistrationView';

import OnboardingView from '@/pages/onboarding/OnboardingView';

import MainView from '@/pages/dashboard/main/MainView';
import SkillView from '@/pages/dashboard/skill/SkillView';
import QuizView from '@/pages/dashboard/quiz/QuizView';
import LevelTestView from '@/pages/dashboard/level-test/LevelTestView';
import NotFound from '@/pages/not-found/NotFound';
import Games from '@/pages/dashboard/main/Games';
import MyStudents from '@/pages/dashboard/main/MyStudents';
import Profile from '@/pages/dashboard/main/Profile';
import MyClasses from '@/pages/dashboard/main/MyClasses';

export enum ROUTE_NAMES {
  LOGIN = 'login',
  REGISTRATION = 'registration',
  ONBOARDING = 'onboarding',
  GAMES = 'games',
  LEVEL_TEST = 'levelTest',
  SKILL = 'skill',
  QUIZ = 'quiz',
  MY_STUDENTS = 'myStudents',
  MY_CLASSES = 'myClasses',
  PROFILE = 'profile',
  NOT_FOUND = 'notFound',
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'login',
        element: <PublicRoute children={<LoginLayout />} />,
        children: [
          {
            path: '',
            element: <LoginView />,
          },
        ],
      },
      {
        path: 'registration/:role',
        element: <PublicRoute children={<RegistrationLayout />} />,
        children: [
          {
            path: '',
            element: <RegistrationView />,
          },
        ],
      },
      {
        path: 'onboarding',
        element: (
          <ProtectedRoute isAdminAllowed children={<OnboardingLayout />} />
        ),
        children: [
          {
            path: '',
            element: <OnboardingView />,
          },
        ],
      },
      {
        path: 'dashboard',
        element: <ProtectedRoute children={<DashboardLayout />} />,
        children: [
          {
            path: '',
            element: <MainView />,
            children: [
              {
                path: 'games',
                element: <Games />,
              },
              {
                path: 'my-students',
                element: <MyStudents />,
              },
              {
                path: 'my-classes',
                element: <MyClasses />,
              },
              {
                path: 'profile',
                element: <Profile />,
              },
            ],
          },
          {
            path: 'level-test',
            element: <LevelTestView />,
          },
          {
            path: 'skill/:type',
            element: <SkillView />,
          },
          {
            path: 'quiz',
            element: <QuizView />,
          },
        ],
      },
      {
        path: 'not-found',
        element: <PublicRoute children={<NotFoundLayout />} />,
        children: [
          {
            path: '',
            element: <NotFound />,
          },
        ],
      },
    ],
  },
]);

export default router;
