import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import classNames from './LoginForm.module.scss';

import AppInput from '../AppInput/AppInput';
import TheButton from '../TheButton/TheButton';

import logo from '@/assets/svg/logo_new.svg';
import { CognitoPasswordChangeProps } from '@/types/login';
import AppLoaderCircle from '../AppLoaderCircle';

const CognitoPasswordChange = ({
  loading,
  styles,
  emitSubmit,
}: CognitoPasswordChangeProps) => {
  const { t } = useTranslation();

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.{}\[\]()?\-\"!@#%&/\\,><':;|_~`+=])[A-Za-z\d\^$*.{}\[\]()?\-\"!@#%&/\\,><':;|_~`+=]{8,}$/;

  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('validation.password_required').toString())
      .matches(passwordRegex),
    confirmPassword: Yup.string()
      .required(t('validation.confirm_password_required').toString())
      .oneOf([Yup.ref('password')], t('passwordsDontMatch').toString()),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: LoginSchema,
    onSubmit: emitSubmit ?? (() => {}),
  });

  const onClear = (
    event: React.MouseEvent<SVGElement, MouseEvent>,
    name: string
  ) => {
    formik.setFieldValue(name, '');
  };

  return (
    <div className={classNames.cognitoPasswordChange}>
      <div className={styles.header}>
        <SVG src={logo} />
        <h2>Britannica English</h2>
        <span>{t('pleaseChooseYourOwnPassword')}</span>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <AppInput
          className={styles.formField}
          inputWrapperClassName={styles.appInputWrapper}
          id="password"
          name="password"
          type="password"
          label={t('new_password') ?? ''}
          placeholder={t('password') ?? ''}
          value={formik.values?.password?.trim()}
          required
          error={formik.errors.password}
          showPasswordRequirementsClose
          allowClear={true}
          emitChange={formik.handleChange}
          emitBlur={formik.handleBlur}
          emitClear={onClear}
          showPasswordRequirements={true}
        />
        <AppInput
          className={styles.formField}
          inputWrapperClassName={styles.appInputWrapper}
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          label={t('confirmPassword') ?? ''}
          placeholder={t('password') ?? ''}
          value={formik.values?.confirmPassword?.trim()}
          required
          error={formik.errors.confirmPassword}
          allowClear={true}
          emitChange={formik.handleChange}
          emitBlur={formik.handleBlur}
          emitClear={onClear}
        />
        {!loading && (
          <TheButton
            className={styles.submit}
            text={t('done')}
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
            shouldRecolor={false}
          />
        )}
        {loading && (
          <div className={styles.loadingWrapper}>
            <AppLoaderCircle />
          </div>
        )}
      </form>
    </div>
  );
};

export default CognitoPasswordChange;
