import UseScreenSize from '@/hooks/UseScreenSize';
import QuizViewMobile from './QuizViewMobile';
import QuizViewDesktop from './QuizViewDesktop';

const QuizView = () => {
  const { isMobile } = UseScreenSize();

  return <>{isMobile ? <QuizViewMobile /> : <QuizViewDesktop />}</>;
};

export default QuizView;
