import React from 'react';
import {
  GameTypeProps,
  GameTypes,
  MultiChoiceProps,
  ClosedSpellingProps,
} from '@/types/game';

import MultiChoice from './MultiChoice/MultiChoice';
import ClosedSpelling from './ClosedSpelling/ClosedSpelling';
import Pronunciation from './Pronunciation/Pronunciation';
import Practice from './Practice/Practice';

const GameTypeComponents = [
  {
    type: GameTypes.Multichoice,
    component: MultiChoice,
  },
  {
    type: GameTypes.ClosedSpelling,
    component: ClosedSpelling,
  },
  {
    type: GameTypes.Pronunciation,
    component: Pronunciation,
  },
  {
    type: GameTypes.Practice,
    component: Practice,
  },
];

const GameType = (
  props: GameTypeProps & (MultiChoiceProps | ClosedSpellingProps)
) => {
  const gameTypeComponent = GameTypeComponents.find(
    (component) => component.type === props.type
  );

  const ComponentToRender = gameTypeComponent && gameTypeComponent.component;

  return ComponentToRender ? <ComponentToRender {...props} /> : null;
};

export default React.memo(GameType);
