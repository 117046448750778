import { useCallback, useEffect, useState } from 'react';
import UseQuiz from './UseQuiz';
import { GameStatus, GameTypes, PopUpTypes } from '@/types/game';
import commonUtils from '@/utils/common';
import { Transition, TransitionStatus } from 'react-transition-group';
import classes from 'classnames';
import classNames from './QuizView.module.scss';

import { SkillTypes } from '@/types/skill';
import { useAppDispatch, useAppSelector } from '@/store';
import { gameActions, gameSelector } from '@/store/reducers/game';
import { useNavigate } from 'react-router-dom';
import { authSelector } from '@/store/reducers/auth';
import UseScreenSize from '@/hooks/UseScreenSize';

import AppModal from '@/components/AppModal';
import QuizPageControls from '@/components/QuizPage/QuizPageControls/QuizPageControls';
import QuizDetails from '@/components/QuizPage/QuizDetails/QuizDetails';
import QuizQuestion from '@/components/QuizPage/QuizQuestion/QuizQuestion';
import QuizPageIndicators from '@/components/QuizPage/QuizPageIndicators/QuizPageIndicators';
import QuizPopUp from '@/components/QuizPage/QuizPopUp/QuizPopUp';
import AppBackdrop from '@/components/AppBackdrop';
import QuizPreparation from '@/components/QuizPage/QuizPreparation/QuizPreparation';
import QuizLanguageToggler from '@/components/QuizPage/QuizLanguageToggler/QuizLanguageToggler';
import WordKnowledgeControls from '@/components/QuizPage/WordKnowledgeControls/WordKnowledgeControls';
import AppLoader from '@/components/AppLoader';
import DictionaryMobile from '@/components/Dictionary/DictionaryMobile/DictionaryMobile';
import UseLocalLang from '@/hooks/UseLocalLang';
import AppBanner from '@/components/AppBanner';
import { levelTestSelector } from '@/store/reducers/level-test';
import levelTestThunks from '@/store/thunks/level-test';
import UseLevelTestGame from '@/hooks/UseLevelTestGame';
import LevelTestLogoutComponent from '../level-test/LevelTestLogoutComponent';
import { TopicTypes } from '@/types/topic';
import EndOfSkillTestPreparation from '@/components/QuizPage/QuizPreparation/EndOfSkillTestPreparation/EndOfSkillTestPreparation';
import { endOfSkillSelector } from '@/store/reducers/end-of-skill';
import FinalAssessmentPreparation from '@/components/FinalAssessmentPreparation/FinalAssessmentPreparation';

const QuizViewMobile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { mediumHeight, windowHeight } = UseScreenSize();

  const {
    loadQuizProgress,
    currentQuestion,
    selectedTopic,
    selectedQuiz,
    selectedSkill,
    selectedType,
    gameStatus,
    currentQuestionIndex,
    currentReadQuestionIndex,
    gameQuestions,
    gameType,
    coins,
    currentTime,
    currentCoins,
    shouldDisplayPopUp,
    popUpType,
    popUpProps,
    isMuted,
    allowRetry,
    showPreQuiz,
    onMute,
    onPause,
    onStartQuiz,
    onPractice,
    onDisableRetry,
    onClosePopUp,
    onAknowledge,
    onSwipe,
    toggleCheckAnswer,
    goToNextQuestion,
  } = UseQuiz();

  const { user, displayDictionary } = useAppSelector(authSelector);
  const { isLevelTestLoading, quizzes, currentQuizIndex } =
    useAppSelector(levelTestSelector);
  const [shouldOpenDictioanry, setShouldOpenDictionary] = useState(false);
  const { startLevelTestGame } = UseLevelTestGame();
  const { currentReadQuizIndex } = useAppSelector(endOfSkillSelector);
  const onOpenDictionary = () => {
    setShouldOpenDictionary(true);
    dispatch(gameActions.toggleGameStatus(GameStatus.SHOW_DICTIOANRY));
  };

  const onCloseDictionary = () => {
    setShouldOpenDictionary(false);
    dispatch(gameActions.toggleGameStatus(GameStatus.PLAYING));
  };

  const onAnswer = useCallback(
    (userAnswer: string) => {
      toggleCheckAnswer(userAnswer);
    },
    [currentQuestion]
  );

  const onGoBack = () => {
    return navigate('/dashboard/games');
  };

  const setClasseByPopType = useCallback(
    (state: TransitionStatus) => {
      return popUpType === PopUpTypes.Hints ||
        popUpType === PopUpTypes.WordsList
        ? `slide-vertical-${state}`
        : `fade-${state}`;
    },
    [popUpType]
  );

  const isPractice = gameType === GameTypes.Practice;
  const { isLevelTest, isFinalAssessment } = useAppSelector(gameSelector);
  const isRead = selectedType === SkillTypes.Read;
  const localLang = UseLocalLang();
  const isInEndOfSkillTest =
    selectedTopic?.topicType === TopicTypes.EndOfSkillTest;

  if (loadQuizProgress || isLevelTestLoading) {
    return <AppLoader />;
  }

  const popUpComponent = (
    <Transition
      in={Boolean(shouldDisplayPopUp && popUpType && popUpProps)}
      timeout={400}
      mountOnEnter
      unmountOnExit
      children={(state) => {
        return (
          <>
            <AppBackdrop
              className={classes(classNames.backdrop, `backdrop-${state}`)}
              emitClose={onClosePopUp}
            />
            <AppModal>
              <QuizPopUp
                {...popUpProps}
                className={classes(classNames.modal, setClasseByPopType(state))}
                type={popUpType}
                tip={
                  commonUtils.getQuestionTip(
                    currentQuestion ?? {},
                    localLang
                  ) ?? ''
                }
              />
            </AppModal>
          </>
        );
      }}
    />
  );

  if (showPreQuiz) {
    if (
      isInEndOfSkillTest &&
      currentReadQuizIndex &&
      currentReadQuizIndex === 1
    ) {
      return (
        <EndOfSkillTestPreparation
          selectedTopic={selectedTopic}
          selectedQuiz={selectedQuiz}
          selectedSkill={selectedSkill}
          selectedType={selectedType}
          emitOnStartQuiz={onStartQuiz}
        />
      );
    } else if (isFinalAssessment) {
      return <FinalAssessmentPreparation />;
    }
  }

  if (showPreQuiz || isRead) {
    return (
      <>
        <QuizPreparation
          currentQuestion={
            //because level test quiz is different from the normal quizzes we need to get the current question of the level test quiz like that.
            isLevelTest
              ? gameQuestions[currentReadQuestionIndex]
              : currentQuestion
          }
          currentQuestionIndex={
            //because level test quiz is different from the normal quizzes we need to get the currentReadQuestionIndex instead of the overall currentQuestionIndex of the quiz.
            isLevelTest ? currentReadQuestionIndex : currentQuestionIndex
          }
          gameQuestions={
            //same as the currentQuestion we need to get the questions of the read question.
            gameQuestions
          }
          loading={loadQuizProgress}
          selectedQuiz={selectedQuiz}
          selectedSkill={selectedSkill}
          selectedTopic={selectedTopic}
          selectedType={selectedType}
          coins={coins}
          currentCoins={currentCoins}
          currentTime={currentTime}
          isPaused={gameStatus === GameStatus.PAUSED}
          tip={
            commonUtils.getQuestionTip(currentQuestion ?? {}, localLang) ?? ''
          }
          emitOnStartQuiz={onStartQuiz}
          emitGoBack={onGoBack}
          emitOnPractice={onPractice}
          emitPause={onPause}
        />
        {popUpComponent}
      </>
    );
  }

  const bottom = isPractice ? (
    <WordKnowledgeControls
      className={classNames.wordKnowledgeControls}
      question={currentQuestion}
      emitOnAknowledge={onAknowledge}
    />
  ) : !isInEndOfSkillTest && !isFinalAssessment ? (
    <QuizPageIndicators
      earnedCoins={coins}
      timesUp={!loadQuizProgress && currentTime <= 5}
      coins={currentCoins}
      timer={currentTime ?? 0}
      selectedType={selectedType}
    />
  ) : (
    <></>
  );

  return (
    <div className="flex flex-col relative" style={{ height: '100dvh' }}>
      {isLevelTest && (
        <LevelTestLogoutComponent className={classNames.levelTestSignOut} />
      )}
      <div
        className={classes('flex flex-1 flex-col relative pb-12 px-4', {
          'pt-16': isFinalAssessment,
          'overflow-y-auto': isPractice,
        })}
        style={{ minHeight: isPractice ? '800px' : '' }}
      >
        {!isLevelTest && !isFinalAssessment && (
          <QuizPageControls
            actual={currentQuestionIndex + 1 ?? 0}
            total={gameQuestions.length}
            onMute={onMute}
            isMuted={isMuted}
            onPause={onPause}
            isPaused={gameStatus === GameStatus.PAUSED}
            loading={loadQuizProgress}
          />
        )}
        <QuizDetails
          shouldDisplayImage={windowHeight < mediumHeight}
          shouldDisplayInstructions={windowHeight > mediumHeight}
          selectedType={selectedType}
          selectedSkill={selectedSkill}
          selectedTopic={selectedTopic}
          selectedQuiz={selectedQuiz}
        />
        {isPractice && <QuizLanguageToggler className="mt-6" />}
        <div className="flex-1 mt-4 mb-4 flex">
          <QuizQuestion
            loading={loadQuizProgress}
            currentQuestion={currentQuestion}
            allowRetry={allowRetry}
            emitOnAnswer={onAnswer}
            emitOnRetry={onDisableRetry}
            emitOnNextQuestion={goToNextQuestion}
            emitOnSwipe={onSwipe}
          />
        </div>
        {!isLevelTest && bottom}
      </div>
      {/* {!isPractice && (
        <DictionaryMobile
          isFixed={false}
          shouldOpen={shouldOpenDictioanry}
          emitOpen={onOpenDictionary}
          emitClose={onCloseDictionary}
        />
      )} */}
      {!isPractice &&
        (displayDictionary ? (
          <DictionaryMobile
            isFixed={false}
            shouldOpen={shouldOpenDictioanry}
            emitOpen={onOpenDictionary}
            emitClose={onCloseDictionary}
          />
        ) : (
          <AppBanner isFixed={true} />
        ))}
      {/* {!isPractice && <AppBanner isFixed={false} />} */}
      {popUpComponent}
    </div>
  );
};

export default QuizViewMobile;
