import classNames from './StudentsData.module.scss';
import SVG from 'react-inlinesvg';
import students from '@/assets/svg/three_students.svg';
import { TeacherGroup } from '@/types/user';
import { useTranslation } from 'react-i18next';

import OnboardingInvitation from '../OnboardingInvitation/OnboardingInvitation';

interface NoStudentsProps {
  currentClassroom: TeacherGroup;
}

const NoStudents = ({ currentClassroom }: NoStudentsProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames.noStudents}>
      <div className={classNames.noStudentsContent}>
        <SVG src={students} />
        <span className={classNames.class}>
          {`${t('class')} ${currentClassroom?.name}`}
        </span>
        <span className={classNames.notJoined}>
          {t('yourStudentsHaveNotJoined')}
        </span>
        <span className={classNames.progressText}>
          {t('asSoonAsTheyStart')}
        </span>
        <OnboardingInvitation
          className={classNames.onboardingInvitation}
          link={currentClassroom?.invitationLinkForStudents}
          isBtn
        />
      </div>
    </div>
  );
};

export default NoStudents;
