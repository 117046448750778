import React, { useState, useRef, useCallback } from 'react';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { DefaultTFuncReturn } from 'i18next';

import { CreateCsvStudentsResponse } from '@/types/response';
import { StudentData, GroupStudentData } from '@/types/student-data';

import ResponseBody from './ResponseBody/ResponseBody';
import StudentRow from './StudentRow/StudentRow';
import AddStudentRow from './AddStudentRow/AddStudentRow';

import classNames from './StudentsTable.module.scss';

import closeIcon from '@/assets/svg/closeFill.svg';

interface StudentsTableProps {
    studentsData: StudentData[] | GroupStudentData[];
    setStudentsData?: React.Dispatch<React.SetStateAction<StudentData[]>>;
    studentsDataResponse?: CreateCsvStudentsResponse | null;
    allowActions?: boolean;
    allowNewRows?: boolean;
    title?: string | DefaultTFuncReturn;
    disableSubmitButton?: boolean;
    setDisableSubmitButton?: React.Dispatch<React.SetStateAction<boolean>>;
    showNewStudentInput?: boolean;
    setShowNewStudentInput?: React.Dispatch<React.SetStateAction<boolean>>;
    isModal?: boolean;
}

const StudentsTable: React.FC<StudentsTableProps> = ({
    studentsData,
    setStudentsData,
    studentsDataResponse,
    allowActions,
    allowNewRows,
    title,
    disableSubmitButton,
    setDisableSubmitButton,
    showNewStudentInput,
    setShowNewStudentInput,
    isModal
}) => {
    const { t } = useTranslation();

    

    const tableContainerRef = useRef<HTMLTableSectionElement>(null);

    const handleAddNewStudentRow = () => {
        if(setShowNewStudentInput) {
            setShowNewStudentInput(true);
            setTimeout(() => {
                handleScroll();
            }, 0);
        }
    }

    const handleScroll = useCallback(() => {
        const tableContainer = tableContainerRef.current;
        if (tableContainer) {
            tableContainer.scrollTop = tableContainer.scrollHeight + 60;
        }
    }, []);

    const unregisteredStudents = studentsDataResponse?.data?.data?.unregisteredStudents?.length || 0;

    return (
        <div className={classNames.component}>
            {unregisteredStudents > 0 && (
                <span className={classNames.submitError}>
                    <SVG src={closeIcon} className={classNames.checkmarkError} />
                    {t('settings.add_students.errors.students_not_registered')}
                </span>
            )}
            <div className={isModal ? `${classNames.wrapper} ${classNames.isModal}` : classNames.wrapper}>
                {title && (
                    <h3 className={classNames.tableTitle}>{title}</h3>
                )}
                <table>
                    <thead>
                        <tr>
                            <th>{t('firstname')}</th>
                            <th>{t('lastname')}</th>
                            <th>{t('email')}</th>
                            <th className={classNames.actionIcons}></th>
                        </tr>
                    </thead>
                    <tbody ref={tableContainerRef}>
                        {studentsData.length > 0 && studentsData.map((student, index) => (
                            <StudentRow
                                student={student}
                                index={index}
                                allowActions={allowActions}
                                studentsData={studentsData}
                                setStudentsData={setStudentsData}
                                setDisableSubmitButton={setDisableSubmitButton}
                            />
                        ))}
                        {studentsDataResponse && (
                            <ResponseBody
                                studentsDataResponse={studentsDataResponse}
                            />
                        )}
                        {showNewStudentInput && setShowNewStudentInput && setStudentsData && studentsData.length < 50 && (
                            <AddStudentRow
                                setStudentsData={setStudentsData}
                                setShowNewStudentInput={setShowNewStudentInput}
                            />
                        )}
                    </tbody>
                </table>
            </div>

            {!studentsDataResponse && allowNewRows && studentsData.length < 50 && (
                <div className={classNames.addStudent}>
                    <button onClick={() => handleAddNewStudentRow()}>{t('settings.add_students.add_student')}</button>
                </div>
            )}
        </div>
    );
};

StudentsTable.defaultProps = {
    allowActions: false,
    allowNewRows: false
};

export default StudentsTable;