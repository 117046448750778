import React from 'react';
import classNames from './QuizQuestion.module.scss';
import classes from 'classnames';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import {
  GameTypes,
  MultiChoiceProps,
  ClosedSpellingProps,
  PracticeProps,
  PronunciationProps,
} from '@/types/game';
import { Question } from '@/types/question';

import GameType from '@/components/GameType/GameType';
import QuizContent from '../QuizContent/QuizContent';
import { SkillTypes } from '@/types/skill';
import UseScreenSize from '@/hooks/UseScreenSize';
import { useTranslation } from 'react-i18next';

interface QuizQuestionProps {
  currentQuestion: Question | null;
  loading: boolean;
  allowRetry: boolean;
  emitOnAnswer: (option: string) => void;
  emitOnRetry: () => void;
  emitOnNextQuestion: () => void;
  emitOnSwipe: (questionId: number) => void;
  emitOnAknowledge?: (id: number, isKnown: boolean) => void;
}

const QuizQuestion = ({
  currentQuestion,
  loading,
  allowRetry,
  emitOnAnswer,
  emitOnRetry,
  emitOnNextQuestion,
  emitOnSwipe,
  emitOnAknowledge,
}: QuizQuestionProps) => {
  const { t } = useTranslation();
  const {
    selectedType,
    pickedAnswer,
    gameType,
    showAnswerInOptions,
    practiceCards,
    possibleAnswers: options,
    isLevelTest,
    isInEndOfSkillTest,
    isFinalAssessment,
  } = useAppSelector(gameSelector);

  const isListen = selectedType === SkillTypes.Listen;
  const isPractice = gameType === GameTypes.Practice;

  const { isMobile, isDesktop, mediumHeight, windowHeight } = UseScreenSize();

  const getGameProps = ():
    | {}
    | MultiChoiceProps
    | ClosedSpellingProps
    | PronunciationProps
    | PracticeProps => {
    if (gameType === GameTypes.Multichoice) {
      return {
        options,
        pickedAnswer,
        showAnswerInOptions,
        emitOnRetry,
        emitOnNextQuestion,
      } as MultiChoiceProps;
    } else if (gameType === GameTypes.ClosedSpelling) {
      return {
        options,
        allowRetry,
        pickedAnswer,
        emitOnRetry,
        emitOnNextQuestion,
      } as ClosedSpellingProps;
    } else if (gameType === GameTypes.Pronunciation) {
      return {
        pickedAnswer,
        allowRetry,
        emitOnRetry,
        emitOnNextQuestion,
      } as PronunciationProps;
    } else if (gameType === GameTypes.Practice) {
      return {
        practiceCards,
        emitOnNextQuestion,
        emitOnSwipe,
        emitOnAknowledge,
      } as PracticeProps;
    }
    return {};
  };
  return (
    <QuizContent
      className={classes(classNames.quizQuestion, {
        [classNames.quizQuestionListen]: isListen,
        [classNames.quizPractice]: isPractice,
        [classNames.quizLevelTest]: isLevelTest,
      })}
      shouldDisplayImageIndicator={
        (isMobile && windowHeight > mediumHeight) ||
        (isMobile && isInEndOfSkillTest)
      }
      shouldDisplayStars={!isPractice}
    >
      {isFinalAssessment && isDesktop && (
        <span className={classNames.finalEnglishLevelTestText}>
          {t('finalEnglishLevelTest')}
        </span>
      )}
      {currentQuestion && (
        <GameType
          type={gameType ?? GameTypes.Multichoice}
          {...getGameProps()}
          currentQuestion={currentQuestion}
          correctAnswer={currentQuestion?.answer}
          emitOnAnswer={emitOnAnswer}
        />
      )}
    </QuizContent>
  );
};

export default React.memo(QuizQuestion);
