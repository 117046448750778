import Navbar from '@/components/Navbar/Navbar';
import { useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import UseUserProgress from '@/hooks/UseUserProgress';
import UserUtils from '@/utils/user';
import { View } from '@/types/common';

import ViewToggler from '@/components/ViewToggler/ViewToggler';
import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
import SkillsGrid from '@/components/SkillsGrid/SkillsGrid';
import UserInfo from '@/components/UserInfo/UserInfo';
import GettingStartedInstructions from '@/components/GettingStartedInstructions/GettingStartedInstructions';
import PremiumSection from '@/components/PremiumSection/PremiumSection';

interface TeacherSidebarProps {
  viewTabs: View[];
  emitSelectView: (view: View) => void;
}

const TeacherSidebar = ({ viewTabs, emitSelectView }: TeacherSidebarProps) => {
  const { user } = useAppSelector(authSelector);

  const fullname = `${user?.firstName || 'N/A'} ${user?.lastName || 'N/A'}`;

  return (
    <>
      <Navbar decreaseOpacity={false} />
      {user && (
        <UserInfo
          isTeacher={UserUtils.isTeacher(user)}
          fullname={fullname}
          profileImage={user.avatar?.avatarName}
          backgroundColor={user.avatar?.avatarColor}
          classesAmount={user.teacherGroups?.length}
          schoolName={user.organizationName}
          studentsAmount={user.totalStudents ?? 0}
        />
      )}
      <ViewToggler
        className="mt-8"
        viewTabs={viewTabs}
        emitSelectView={emitSelectView}
      />
      {/* <SectionSeperator className="my-6" />
      <SkillsGrid
        progress={userProgress}
        loading={loadingProgress}
        shouldHighlight={false}
        shouldExpand={false}
      /> */}
      <SectionSeperator className="my-6" />
      <GettingStartedInstructions />
      <PremiumSection className="mt-8" />
    </>
  );
};

export default TeacherSidebar;
