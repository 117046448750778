import React, { useMemo, useRef, useState } from 'react';
import classNames from './MultiChoiceQuestion.module.scss';
import classes from 'classnames';
import { Question } from '@/types/question';
import GameUtils from '@/utils/gameUtils';
import { SkillTypes } from '@/types/skill';
import SVG from 'react-inlinesvg';
import { gameSelector } from '@/store/reducers/game';
import { useAppSelector } from '@/store';
import { useTranslation } from 'react-i18next';
import {
  interfaceLanguageSelector,
  studyingLanguageSelector,
} from '@/store/reducers/auth';

import WaveForm from '@/components/AudioWave/Waveform';

import questionIcon from '@/assets/svg/question.svg';
import replyIcon from '@/assets/svg/reply.svg';
import soundPlay from '@/assets/svg/speaker.svg';
import AppLoader from '@/components/AppLoader';
import AppLoaderCircle from '@/components/AppLoaderCircle';

type MultiChoiceQuestionProps = {
  selectedType: SkillTypes | null;
  question: Question;
  handleRetry?: () => void;
  emitOnNextQuestion?: () => void;
};

const MultiChoiceQuestion = ({
  selectedType,
  question,
  handleRetry,
  emitOnNextQuestion,
}: MultiChoiceQuestionProps) => {
  const { t } = useTranslation();
  const {
    showOverlay,
    isLevelTest,
    isInEndOfSkillTest,
    isFinalAssessment,
    pickedAnswer,
  } = useAppSelector(gameSelector);
  const interfaceLanguage = useAppSelector(interfaceLanguageSelector);
  const studyingLanguage = useAppSelector(studyingLanguageSelector);

  const [isFetchingSoundLoading, setIsFetchingSoundLoading] = useState(false);
  const [sound, setSound] = useState({ base64String: '', soundPath: '' });

  const questionParts = GameUtils.getMultiChoiceQuestionParts(question);
  const reply = GameUtils.questionReply(question);

  const isVocabulary = selectedType === SkillTypes.Vocabulary;
  const isListen = selectedType === SkillTypes.Listen;

  const soundPath =
    isLevelTest || isFinalAssessment
      ? question.soundPath
      : question.dictionaryDetails?.soundPath;

  const onPlaySound = async () => {
    if (sound.base64String === '' || sound.soundPath !== soundPath) {
      if (soundPath) {
        setIsFetchingSoundLoading(true);
        const base64String = await GameUtils.playSoundFromUrl(soundPath);
        setSound((prev) => ({ ...prev, base64String, soundPath }));
      }
    } else {
      new Audio(sound.base64String).play();
    }

    setIsFetchingSoundLoading(false);
  };

  const blanksAmount = questionParts.filter((part) => part.isBlank).length;

  const buildContent = () => {
    if (isListen) {
      return (
        <div className={classNames.listenContent}>
          {showOverlay && (
            <div className={classNames.overlay}>
              <span>{t('tryAgainOrKeepGoing')}</span>
              <div
                className={classNames.buttons}
                style={{
                  direction:
                    interfaceLanguage?.direction as React.CSSProperties['direction'],
                }}
              >
                {!isInEndOfSkillTest && (
                  <button
                    className={classNames.buttonWrapper}
                    onClick={() => {
                      if (handleRetry !== undefined) {
                        handleRetry();
                      }
                    }}
                  >
                    <div
                      className={classes(
                        classNames.buttonContent,
                        classNames.retry
                      )}
                    >
                      <span>1</span>
                    </div>
                  </button>
                )}
                <button
                  className={classNames.buttonWrapper}
                  onClick={() => {
                    if (emitOnNextQuestion) {
                      emitOnNextQuestion();
                    }
                  }}
                >
                  <div
                    className={classes(
                      classNames.buttonContent,
                      classNames.next,
                      [classNames[`next-${interfaceLanguage?.direction}`]]
                    )}
                  ></div>
                </button>
              </div>
            </div>
          )}
          {question.soundPath && <WaveForm audio={question.soundPath ?? ''} />}
        </div>
      );
    }

    return (
      <>
        <div
          className={classNames.questionItem}
          style={{
            direction:
              (studyingLanguage?.direction as React.CSSProperties['direction']) ??
              'ltr',
          }}
        >
          <SVG src={questionIcon} />
          <div className={classNames.text}>
            {questionParts.map((part, index) => {
              const { isBlank, value, correctAnswerPart } = part;

              let content = <span>{value}</span>;

              let blankFill = value;

              if (!isInEndOfSkillTest && isBlank && pickedAnswer) {
                blankFill =
                  blanksAmount === 1
                    ? question?.answer
                    : correctAnswerPart ?? '';
              }

              if (isBlank) {
                content = (
                  <span
                    className={classes(classNames.specialFill, {
                      [classNames.specialFillFilled]: isBlank,
                    })}
                  >
                    {blankFill}
                  </span>
                );
              }

              return <React.Fragment key={index}>{content} </React.Fragment>;
            })}
          </div>
        </div>

        {reply && (
          <div
            className={classNames.questionItem}
            style={{
              direction:
                (studyingLanguage?.direction as React.CSSProperties['direction']) ??
                'ltr',
            }}
          >
            <img src={replyIcon} alt="question" />
            <div className={classNames.text}>
              <span>{reply}</span>
            </div>
          </div>
        )}
      </>
    );
  };

  const content = isVocabulary ? (
    <div className={classNames.vocabularyContent}>
      {soundPath &&
        (isFetchingSoundLoading ? (
          <AppLoaderCircle className="w-full h-full" />
        ) : (
          <button className={classNames.soundBtn} onClick={onPlaySound}>
            <SVG src={soundPlay} />
          </button>
        ))}
      <span
        className={classes([
          classNames[`question-${studyingLanguage?.direction}`],
        ])}
      >
        {question.question}
      </span>
    </div>
  ) : (
    buildContent()
  );

  return (
    <div className={classes(classNames.multiChoiceQuestion)}>{content}</div>
  );
};

export default MultiChoiceQuestion;
