export const TRANSLATIONS_HE = {
  CodeMismatchException: "קוד לא תקין",
  ExpiredCodeException: "קוד לא תקין",
  IAlreadyHaveAnAccount: "כבר יש לי חשבון",
  LimitExceededException: "נגמר מספר הניסיונות האפשריים, נא לנסות שוב לאחר זמן מה.",
  UserNotConfirmedException: "החשבון שלך לא מאומת. נא להזין את קוד האימות שנשלח למייל שלך.",
  accountVerifiedCanLoginNow: "החשבון שלך אומת, עכשיו אפשר להתחבר",
  account_settings: "הגדרות חשבון",
  achievmentsSection: {
    feedback: "הפגנת יכולות מרשימות!",
    greatJob: "שיחקת אותה!",
    "in": "ב",
    weeklyAchievement: "ההישג של השבוע"
  },
  addClass: "הוספת כיתה",
  addNewClass: "הוספת כיתה חדשה",
  advanceToTheNextLevel: "להתקדם לרמה הבאה.",
  advanced: "רמה מתקדמת",
  again: "שוב",
  all: "הכול",
  allDone: "הכול מוכן!",
  allLevels: "כל הרמות",
  allowIndividualAdvancement: "מתן אפשרות להתקדם לרמה הבאה",
  almostFinish: "כמעט סיימת!",
  almostHalfWayThere: "כמעט סיימת חצי מהשאלות!",
  almostMadeIt: "כמעט סיימת",
  almostThere: "עוד קצת וסיימת...",
  almost_made_it: "כמעט סיימת",
  amazingJob: "כל הכבוד!",
  amazing_job: "כל הכבוד!",
  anErrorOccurred: "אירעה שגיאה",
  anUnauthorizedRequestErrorOccurred: "שגיאה, הבקשה אינה מורשית.",
  andBeginYour: "כדי ללמוד אנגלית.",
  andInviteYourStudents: "ולהזמין את התלמידים שלך",
  andThenInvite: "ואחר כך להזמין את התלמידים שלך להצטרף אליה",
  andYouCanImproveYouScores: "ולשפר את הציונים שלך.",
  and_got_all_the_stars: "וקיבלת את כל הכוכבים",
  answeredIncorrectly: ", ענית עליהן לא נכון.",
  appName: "מורפיקסקול 2.0",
  appTitle: "מורפיקסקול 2.0",
  app_banner: "באנר אפליקציה",
  ar: "عربية",
  areYouReadyForTheQuiz: "רוצה להתחיל את החידון?",
  areYouSure: "האם אתם בטוחים?",
  asSoonAsTheyStart: "ברגע שהם יתחילו ללמוד, ההתקדמות שלהם תופיע כאן.",
  assessment: "מבדק סיום",
  assessments: "הערכות",
  assignments: "משימות",
  atLeastOneNumber: "הסיסמה צריכה להכיל מספר אחד לפחות",
  atLeastOneSpecial: "הסיסמה צריכה להכיל לפחות תו אחד מיוחד",
  atLeastOneUppercase: "הסיסמה צריכה להכיל אות גדולה אחת לפחות",
  averageStudentLevel: "רמה ממוצעת לתלמיד",
  avgAttempts: "מספר ניסיונות ממוצע",
  avgClassScores: "ממוצע ציוני כיתה",
  avgFailureRate: "אחוז כישלון ממוצע",
  avgGameScores: "ממוצע ציוני חידונים",
  avgPassRate: "אחוז הצלחה ממוצע",
  avgQuizzes: "ממוצע חידונים שהושלמו בידי כל תלמיד",
  avgTimeSpent: "זמן ביצוע ממוצע",
  back: "חזרה",
  backHome: "חזרה לדף הבית",
  backToHomePage: "חזרה לדף הבית",
  backToTopics: "חזרה לתפריט החידונים",
  back_home: "חזרה לדף הבית",
  badgesSection: {
    link: "צפייה בכולם",
    title: "התגים האחרונים שלי"
  },
  britannicaEducationWith: "לשאלות והערות.",
  byClickingOn: "אם בוחרים שם מרשימת התלמידים, אפשר לראות את ההתקדמות של כל אחת ואחד בכל תחום לימוד ואת התדירות של השימוש בתוכנה. אפשר גם לבחור את שפת הממשק שלהם.",
  cancel: "ביטול",
  cancelAnyway: "בטל בכל זאת",
  changeLevel: "שינוי רמה",
  change_password: "החלפת סיסמה",
  characters: "תווים",
  checkInSpamMessage: "לא קיבלת את המייל? כדאי לבדוק בתיקיית <0> הספאם</0>  שלך.",
  checkInSpamMessageTeacher: "",
  choose: "בחירה",
  chooseAClass: "יש לבחור כיתה",
  chooseAvatar: "בחירת אווטר",
  chooseDate: "בחירת תאריך",
  chooseGrade: "בחירת שכבה",
  chooseTheCorrectAnswer: "יש לבחור את התשובה הנכונה למילוי המשפט",
  chooseYourFavoriteAvatar: "נא לבחור אווטר וצבע",
  city: "יישוב",
  "class": "כיתה",
  classActivity: "פעילות כיתתית",
  classAvg: "ממוצע כיתתי",
  classCreated: "הכיתה נוצרה בהצלחה!",
  classCreatedDescription: "התלמידים שלך קיבלו אימייל עם הוראות לאיפוס הסיסמה שלהם. לאחר שהם יעשו זאת, הם יתחילו את המסע שלהם ללימוד אנגלית.",
  className: "שם הכיתה",
  classReport: "דוח כיתתי",
  classes: "כיתות",
  clickOnAStudent: "יש לבחור שם ברשימת התלמידים",
  clickToCopy: "העתקת קישור להזמנה",
  clickToTryAgain: "לחצו על סימן המיקרופון כדי לנסות שוב",
  clickToWatchAssessments: "יש ללחוץ לצפייה בהערכות של התלמידים",
  coins: "מטבעות",
  coinsWillBeLost: "והמטבעות שצברת לא יישמרו.",
  complete: "סיימת",
  confirmPassword: "אישור הסיסמה",
  contactOurTeam: "צרו איתנו קשר",
  contactOurTeamWithQuestions: "צור קשר עם הצוות שלנו עם שאלות וחששות.",
  contactUs: "כתבו לנו",
  "continue": "המשך",
  continueCreating: "המשך ביצירה",
  continueOn: "להמשיך עם",
  continueOnWeb: "המשך באתר",
  continueQuiz: "המשך",
  continueToQuestions: "המשך לשאלות",
  continue_level_test: "המשך במבחן הרמה",
  copy: "העתקה",
  copyInvitationLink: "העתקת קישור להזמנה",
  copyLink: "העתקת קישור",
  copyLinkOnly: "העתקת הקישור בלבד",
  country: "מדינה",
  create: "יצירה",
  createClass: "יצירת כיתה",
  createNewClass: "יצירת כיתה חדשה",
  createYourClass: "נא ליצור כיתה ולהזמין אליה את התלמידים שלך",
  createYourFirstClass: "כאן אפשר ליצור את הכיתה הראשונה שלך",
  currentLevel: "רמה נוכחית",
  current_password: "הסיסמה הנוכחית",
  customYourLearning: "בקרוב נוכל להתאים לך מסלול למידה אישי!",
  dashboard: "התקדמות",
  date: "תאריך",
  dearSchoolAdmin: "שלום רב,",
  dearStudent: "שלום רב, קיבלת מהמורה",
  dearTeacher: "שלום למורה, קיבלת הזמנה להצטרף לבית הספר",
  dearTeacherLevel: "שלום למורה,",
  dearTeacherPleaseJoin: "שלום למורה, הוזמנת להצטרף לבית הספר {{schoolName}} כדי ליצור קבוצות לימוד ולהתחיל להשתמש במערכת מורפיקסקול 2.0 ללימוד אנגלית.",
  deleteClass: "מחק כיתה",
  dictionary: "מילון",
  didYouMean: "האם התכוונת ל:",
  didntQuiteHearThat: "מה שאמרת לא נקלט במערכת. נא ללחוץ על סימן המיקרופון כדי לנסות שוב.",
  discoverAWorld: "מזמינים אותך ליהנות ממגוון פעילויות ומסלולי למידה כדי לשדרג את האנגלית שלך.",
  doingGreat: "התקדמת יפה!",
  done: "בוצע",
  dontKnow: "לא ידוע לי",
  downloadAndView: "הורדה וצפייה של",
  editClass: "עריכת כיתה",
  email: "כתובת מייל",
  emailAlreadyExists: "כתובת המייל כבר רשומה במערכת",
  emailExample: "example@gmail.com",
  emailIsValidReceiveVerificationCode: "אם כתובת האימייל נכונה, יתקבל מייל עם קוד אימות להמשך תהליך איפוס הסיסמה.",
  emailVerification: "אימות דואר אלקטרוני",
  empowerEnrichAndInspire: "עם מורפיקסקול 2.0",
  en: "אנגלית",
  endOfLevelAssessment: "מבחן הערכה לסיום רמה",
  english: "אנגלית",
  englishLearningJourney: "ללמד אנגלית עם מורפיקסקול 2.0.",
  englishLearningJourneyMorfix: "ללמד אנגלית עם מורפיקסקול 2.0.",
  englishSkillsAssessment: "הערכת מיומנויות באנגלית",
  englishSkillsAssessments: "הערכות מיומנויות באנגלית",
  englishSkillsProgress: "התקדמות בתחומי הלימוד",
  enterCodeHere: "נא להזין את הקוד",
  enterNewPassword: "‏‎סיסמה חדשה‎‏",
  enterVerificationCode: "נא להזין קוד אימות",
  enterVerificationCodeAndNewPassword: "יש להזין את קוד האימות ואת הסיסמה חדשה",
  es: "ספרדית",
  exampleEmail: "example@gmail.com",
  exampleSentences: "דוגמאות שימוש",
  examples: "דוגמאות",
  excellent: "רמה מצוינת",
  exit: "יציאה",
  extra: "תוספת",
  extraCoins: "מטבעות נוספים",
  failed_quiz_message_again: "עדיין לא עברת בהצלחה את החידון, לכן לא קיבלת עוד מטבעות או כוכבים.",
  failed_quiz_message_first: "למרבה הצער לא עברת, לכן לא קיבלת מטבעות על החידון הזה.",
  failed_quiz_message_passed: "לא עברת. אל דאגה, הציון הקודם שקיבלת נשמר.",
  failureRate: "אחוז כישלון",
  fewMoreSeconds: "רק עוד כמה שניות",
  fieldIsMandatory: "נא למלא את השדה הזה",
  filter: "סינון",
  finalAssessment: "הערכה סופית",
  finalEnglishLevelTest: "מבחן סוף רמה באנגלית",
  firstname: "שם פרטי",
  focus: "יצאת מפוקוס?",
  "for": "בשביל",
  forgotPassword: "שכחתי סיסמה",
  foundation: "רמה בסיסית",
  friday: "יום שישי",
  fridayShort: "ו'",
  from: "מ",
  fullVersionAvilableOnlyOnDesktop: "הגרסה המלאה זמינה רק במחשב",
  generalLevel: "רמה כללית",
  generateClassReport: "יצירת דו\"ח כיתתי",
  generateReport: "יצירת דו\"ח",
  getApp: "להורדת האפליקציה",
  getFullExperience: "מוזמנים להשלים את חוויית הלימוד עם אפליקציית מורפיקסקול 2.0.",
  getStarted: "נתחיל",
  gettingStarted: "בואו נתחיל",
  giveFeedback: "כתבו לנו",
  goAheadAndShareThisLinkWithYourStudentsNow: "עכשיו אפשר לשתף את הקישור הזה עם התלמידים שלך.",
  goAheadJoinMyClass: "היי, מוזמנים ללחוץ על הקישור כדי להצטרף לכיתה שלי",
  goBackToSkills: "חזרה לתחומי הלימוד",
  goToLevelTest: "מבחן רמה",
  goToSkill: "לחידוני {{skill}}",
  go_back_btn: "כפתור 'חזרה'",
  good: "יפה",
  goodJob: "יפה מאוד",
  goodLuck: "בהצלחה!",
  gotAllStars: "והשגת את כל הכוכבים",
  gotThis: "הבנתי!",
  grade: "שכבה",
  grade_1: "א'",
  grade_10: "י'",
  grade_10th: "י'",
  grade_11: "י״א",
  grade_11th: "י״א",
  grade_12: "י״ב",
  grade_12th: "י״ב",
  grade_1st: "א'",
  grade_2: "ב'",
  grade_2nd: "ב'",
  grade_3: "ג'",
  grade_3rd: "ג'",
  grade_4: "ד'",
  grade_4th: "ד'",
  grade_5: "ה'",
  grade_5th: "ה'",
  grade_6: "ו'",
  grade_6th: "ו'",
  grade_7: "ז'",
  grade_7th: "ז'",
  grade_8: "ח'",
  grade_8th: "ח'",
  grade_9: "ט'",
  grade_9th: "ט'",
  grammar_tip: "הסבר",
  great: "יופי!",
  greatJob: "כל הכבוד!",
  he: "עברית",
  hello: "שלום",
  helloWorld: "",
  hi: "היי",
  hints: "עזרה",
  hints_options: {
    add_more_time: "הוספת זמן",
    hear_the_english_word: "השמעת המילה",
    remove_a_letter: "צמצום אותיות",
    remove_one_answer: "צמצום תשובות",
    see_the_translation: "הצגת התרגום",
    see_the_word: "הצגת המילה",
    show_a_letter: "הצגת אות"
  },
  homepage: "דף הבית",
  howsYourEnglish: "מהי רמת האנגלית שלך?",
  iAgreeTo: "ברצוני לאשר את",
  id: "מספר אישי",
  illDoItLater: "אעשה זאת מאוחר יותר",
  improveYourRecentGames: "שיפור החידונים האחרונים שתרגלת",
  improveYourRecentVocabularyQuizzes: "שיפור ההישגים בחידוני אוצר המילים האחרונים שלך",
  inAllGames: "בכל החידונים",
  in_order_to_proceed_ask_teacher: "כדי להמשיך לרמה הבאה, על המורה שלך לאפשר לך לעשות זאת.",
  in_order_to_start: "כדי להתחיל את הלמידה שלך, עלינו לדעת",
  incorrect: "לא נכון",
  incorrect_answer: "שגויה",
  initialLevelSetTest: "מבחן ראשוני לקביעת רמה",
  initialLevelTest: "מבחן רמה ראשוני",
  instructionalMaterial: "חומרי הוראה",
  interfaceLanguage: "שפת ממשק",
  interface_language: "שפת ממשק",
  intermediate: "רמה בינונית",
  invalidCode: "קוד לא תקין",
  invalidEmail: "כתובת המייל אינה תקינה",
  invalidField: "שגיאה בשדה זה",
  invalidFirstname: "שגיאה בשם הפרטי",
  invalidLastname: "שגיאה בשם המשפחה",
  invalidPassword: "הסיסמה אינה לפי הדרישות",
  invalidUsernameOrPassword: "שם משתמש או סיסמה שגויים",
  invitationLink: "קישור להזמנה",
  invitationLinkWontChange: "אל דאגה - הקישור להזמנה לא ישתנה",
  inviteYourStudents: "הזמנת התלמידים שלך",
  inviteYourTeachers: "נא להזמין מורות ומורים ליצור כיתות.",
  invitedToJoin: "קיבלת הזמנה לצרף את בית הספר שלך למורפיקסקול 2.0.",
  invitedToJoinMorfix: "קיבלת הזמנה להצטרף למורפיקסקול 2.0.",
  itLooksLikeYouReallyKnowTheMaterial: "נראה שלמדת היטב את החומר והתקדמת יפה. יש לסיים את החידונים בכל תחומי הלימוד ולהמשיך להתקדם.",
  itsTime: "הגיע הזמן לבחור אם להתחיל את החידון,",
  joinHere: "להצטרפות",
  joinNow: "להצטרפות",
  keepGoing: "קדימה",
  keepPlaying: "כדאי להמשיך לשחק!",
  keepUpTheGoodWork: "בהצלחה בהמשך הלמידה",
  know: "ידוע לי",
  know_your_level: "את רמת האנגלית שלך.",
  language: "שפה",
  language_of_study: "שפת לימוד",
  languages: {
    ar: "ערבית",
    en: "אנגלית",
    es: "ספרדית",
    he: "עברית",
    pt: "פורטוגלית"
  },
  lastname: "שם משפחה",
  leave_level_test_and_sign_out: "רוצה לעזוב את מבחן הרמה ולהתנתק?",
  letsAssess: "נבדוק את רמת האנגלית הנוכחית שלך בחידון קצר, כדי שנוכל להציע לך את חוויית הלמידה הטובה ביותר. החידון יימשך בערך 5-15 דקות בהתאם לביצועים שלך. שנתחיל?",
  letsBegin: "נתחיל",
  letsFinish: "נסיים",
  letsGo: "קדימה",
  letsGoFinalAssessment: "קדימה",
  letsKeepLearning: "נמשיך ללמוד!",
  letsSee: "כך אפשר לבדוק את מידת השליטה שלך במילים בנושא הזה.",
  letsSeeIfYouReady: "נבדוק את מידת המוכנות שלך",
  level: "רמה",
  levelAchievement: "הרמה שהגעת אליה",
  levelPerSkill: "רמה לכל תחום לימוד",
  levelTest: "מבחן רמה",
  levelTestIncomplete: "מבחן הרמה לא הושלם",
  linkCopied: "הקישור הועתק",
  listen_tip: "טיפ בהקשבה",
  listeningTapToFinish: "מקליט. לחצו על סימן המיקרופון כדי לשלוח את התשובה",
  loading_spinner: "בטעינה",
  loginFromDifferent: "התחברות מחשבון אחר",
  logout: "יציאה מהחשבון",
  looksLikeYouOnARoll: "שיחקת אותה",
  melingoCalculateYourLevel: "אנחנו מחשבים את הרמה שלך...",
  menu_button: "כפתור התפריט",
  message: "הודעה",
  monday: "יום שני",
  mondayShort: "ב'",
  moreTime: "עוד זמן",
  moreTimes: "פעמים נוספות",
  mustFirstCreateClass: "על מנת שהתלמידים שלך יתחילו להשתמש בפלטפורמה, תחילה עליך ליצור כיתה ולאחר מכן להזמין אותם להצטרף.",
  myProfile: "הפרופיל שלי",
  myProgress: "ההתקדמות שלי",
  myRecentTranslations: "מילים שחיפשתי",
  needHelp: "עזרה",
  new_password: "‏‎סיסמה חדשה‎‏",
  new_password_verification: "אישור הסיסמה החדשה",
  next: "הבא",
  nextQuestion: "לשאלה הבאה",
  nextQuiz: "לחידון הבא",
  nextUpInYourLearningPath: "החידונים הבאים במסלול הלמידה שלך",
  nextWord: "למילה הבאה",
  next_button: "כפתור 'הבא'",
  noCoins: "אין מטבעות",
  noDataToDisplay: "אין נתונים להצגה",
  noExtraCoins: "אין תוספת מטבעות",
  noExtraCoinsThisTime: "אז הפעם אין תוספת מטבעות",
  noResultsFor: "לא נמצא תרגום מילוני עבור {{word}}",
  none: "אחר",
  notBad: "לא רע",
  notMadeIt: "נראה שלא הצלחת בפעם הזאת. אפשר",
  notMadeItInTime: "לא הספקת לענות על השאלה בזמן. עוברים לשאלה הבאה.",
  ofThisSite: "של אתר זה.",
  ok: "בסדר",
  okLetsBegin: "קדימה, נתחיל!",
  okLetsGo: "אוקיי, קדימה!",
  oneMoreMistake: "אם תהיה לך עוד טעות, החידון יסתיים,",
  onlyNumbersAllowed: "אפשר לכתוב רק מספרים",
  oops: "אופס",
  orClickHereToSendAgain: "או <0> ללחוץ כאן </0> כדי לשלוח את הקוד שוב.",
  orClickHereToSendAgainTeacher: "",
  outOf: "מתוך",
  overallProgress: "התקדמות כוללת",
  passRate: "אחוז הצלחה",
  password: "סיסמה",
  passwordMinimumLength: "הסיסמה צריכה להיות באורך 8 תווים לפחות",
  password_changed_successfully: "שינית בהצלחה את הסיסמה",
  passwordsDontMatch: "הסיסמאות אינן תואמות.",
  passwords_must_match: "הסיסמאות אינן תואמות. יש לנסות שוב",
  pause_game: "הפסקת החידון",
  paussed: "הופסק",
  perGame: "לכל חידון",
  perLevel: "לכל רמה",
  perSkill: "לפי תחום לימוד",
  phone: "טלפון",
  phraseOfTheDay: "ביטוי היום",
  phrasesFor: "ביטויים עבור",
  pictureOfTheDay: "תמונת היום",
  play_sound: "השמעת קול",
  pleaseChooseYourOwnPassword: "נא לבחור סיסמה משלך",
  pleaseCompleteTheFollowingTest: "נא לענות על המבחן עד סופו. אין צורך להזדרז. יש לקרוא את ההוראות ולעשות כמיטב יכולתך.",
  pleaseContact: "נא ליצור קשר עם {{studentName}} ישירות ולבקש להתנתק ולהיכנס שוב כדי לראות את השינויים.",
  pleaseContactYourSchoolManagerForGuidance: "נא לפנות להנהלת בית הספר שלך לקבלת הדרכה.",
  pleaseEnter: "נא להזין",
  pleaseEnterTheFollowing: "נא למלא את הפרטים הדרושים כדי להתחיל",
  pleaseEnterYourDetails: "נא להזין את הפרטים שלך",
  pleaseSelectYourAccount: "נא לבחור את החשבון שלך או להתחבר מחשבון אחר",
  pleaseTakeAMoment: "נא לרשום את בית הספר.",
  pleaseTryAgainLaterWeApologize: "נא לנסות שוב אחר כך. עימך הסליחה על אי-הנוחות. ",
  pleaseWriteOnlyLetters: "נא לכתוב אותיות בלבד",
  powerFulWords: "מעצימות אותנו ומעוררות בנו השראה",
  practice: "התחלת תרגול",
  practiceAgain: "לתרגל שוב",
  practiceProperPronunciation: "מזמינים אותך לתרגל הגייה נכונה.",
  practiceYourVocabulary: "תרגול אוצר המילים שלך",
  practice_makes_perfect: "לא להתייאש, להמשיך לתרגל!",
  preferences: "העדפות",
  premiumSection: {
    description: "כאן אפשר לקבל מידע חשוב על מקורות לימוד מנציגי בית הספר שלך או מצוות מורפיקסקול 2.0.",
    title: "מסר למורים",
    unlock: "עוד"
  },
  previous_button: "כפתור 'הקודם'",
  processing: "בתהליך",
  proficiency: "רמה טובה",
  profile: "פרופיל",
  progress: "התקדמות",
  pt: "פורטוגלית",
  qs: "שאלות",
  question: "שאלה",
  questions: "שאלות",
  questionsAndConcerns: "העומדות לפניכם",
  questionsLeft: "שאלות שנותרו",
  quiz: "החידון",
  quizzes: "חידונים",
  quizzesCompleted: "חידונים שהשלמת",
  quizzesSection: {
    comingUpQuizzesTitle: "החידונים הבאים",
    lastQuizzesSubTitle: "שיפור מ-1/2 כוכב ל-3 כוכבים",
    lastQuizzesTitle: "שיפור החידונים האחרונים שלך"
  },
  ran_out_of_time: "שוב נגמר הזמן. השאלה תסומן כ",
  readMore: "עוד",
  readTheText: "יש לקרוא את הקטע ולענות על השאלות",
  read_tip: "טיפ בקריאה",
  readyToChooseYourAvatar: "רוצה לבחור את האווטר שלך?",
  receiveTipsAndUpdates: "אשמח לקבל טיפים ועדכונים ממורפיקסקול 2.0 מדי פעם",
  receiveTipsAndUpdatesMorfix: "אשמח לקבל טיפים ועדכונים ממורפיקסקול 2.0 מדי פעם",
  recentLogin: "ההתחברות האחרונה",
  rememberThis: "לא שכחת?",
  remind_teacher_to_move_up: "יש להזכיר למורה שלך להעלות את הרמה שלך.",
  requirements: "דרישות",
  resendVerificationCode: "לא קיבלת? שלחו אליי שוב את הקוד",
  resultForTheTranslation: "תוצאה לתרגום של",
  results: "תוצאות",
  resultsForTheTranslation: "תוצאות תרגום לעברית עבור:",
  resume: "המשך",
  reward: "פרס",
  saturday: "יום שבת",
  saturdayShort: "ש'",
  save: "שמירה",
  saveChanges: "לשמור שינויים?",
  schoolName: "שם בית הספר",
  score: "ציון",
  searchForAStudent: "יש לחפש תלמידים",
  searchForStudent: "חיפוש תלמידים",
  selectSkill: "בחירת מיומנות",
  selfPracticeProgress: "התקדמות בתרגול",
  selfPracticeStreak: "רצף התרגול שלי",
  send: "שליחה",
  sendNow: "שליחה",
  settings: {
    about_me: "הפרטים שלי",
    about_the_product: "על המוצר",
    add_students: {
      add_student: "הוספת תלמידים",
      add_students: "הוספת תלמידים",
      add_students_manually: "הוספת תלמידים באופן ידני",
      button_next: "הבא",
      click_to_browse: "ללחוץ כדי לחפש את הקובץ במחשב שלך",
      create_students: "יצירת תלמידים חדשים",
      download: "הורדת",
      download_sample: "קובץ xlsx לדוגמה.",
      drag_here: "אפשר לגרור קבצים לכאן או ",
      email_will_be_send: "אימייל יישלח",
      enroll_students: "רישום תלמידים",
      errors: {
        bad_formatting: "הנתונים בקובץ אינם תקינים.  <br/> יש לבדוק את הנתונים ולתקן אותם לפי הצורך",
        email_in_use: "כתובת הדוא\"ל הזאת כבר רשומה",
        email_incorrect: "נא להזין כתובת דוא\"ל חוקית.",
        file_empty: "ניסית להעלות קובץ ריק",
        file_too_large: "הקובץ גדול מדי. הגודל המרבי המותר הוא 256KB.",
        first_name_incorrect: "השם הפרטי מכיל תווים לא חוקיים.",
        first_name_missing: "יש להזין שם פרטי.",
        last_name_incorrect: "שם המשפחה מכיל תווים לא חוקיים.",
        last_name_missing: "יש להזין שם משפחה.",
        limit_reached: "המספר המרבי של תלמידים בכיתה הוא 50. <br/>נא להסיר את הקובץ ולהעלות קובץ חדש.",
        limit_reached_description: "הכיתה שלך מכילה את מספר התלמידים המרבי המותר.",
        limit_reached_title: "הכיתה הזאת כבר מלאה.",
        students_not_registered: "חלק מהתלמידים לא נרשמו",
        wrong_format: "הפורמט של הקובץ אינו נכון. . \n<br/>נא לוודא שהעלית קובץ xlsx."
      },
      invite_students: "הזמנת תלמידים",
      preview: "רשימת תלמידים",
      students_preview: "תצוגה מקדימה",
      success_description: "התלמידים שלך קיבלו אימייל עם הוראות לאיפוס הסיסמה שלהם. לאחר שהם יעשו זאת, הם יתחילו את המסע שלהם ללימוד אנגלית.",
      success_title: "הכיתה נוצרה בהצלחה!",
      upload_description: "לאחר העלאת הקובץ בהצלחה, התלמידים שלך יהיו רשומים במערכת. הם יקבלו סיסמה זמנית לאימייל שלהם ויוכלו להתחבר למורפיקסקול 2.0.",
      upload_file: "העלאת קובץ ה-xlsx של התלמידים שלך",
      upload_instruction: "אפשר להוריד קובץ לדוגמה.",
      upload_successful: "הקובץ שלך הועלה בהצלחה"
    },
    change_password: "החלפת סיסמה",
    "class": "הכיתה שלי",
    current_level: "רמה נוכחית:",
    grade: "כיתה",
    home: "דף הבית",
    language: "שפה",
    logout: "יציאה מהחשבון",
    my_classes: "הכיתות שלי",
    my_games: "החידונים שלי",
    my_students: "התלמידים שלי",
    my_tutor: "המורה הפרטי שלי",
    native_language: "שפת אם",
    privacy_policy: "מדיניות פרטיות",
    profile: "פרופיל",
    school_id: "בית הספר שלי",
    security: "אבטחה",
    sound: "צליל",
    title: "הגדרות",
    username: "השם שלי"
  },
  showMore: "עוד",
  signIn: "כניסה לחשבון",
  signYouIn: "מתחבר לחשבון שלך...",
  sign_out: "יציאה מהחשבון",
  sincerely: "בברכה, {{name}}",
  sixDigits: "6 ספרות",
  sixDigitsAtLeast: "הקוד חייב להיות בן 6 ספרות לפחות",
  skills: {
    grammar: "דקדוק",
    listen: "הקשבה",
    read: "הבנת הנקרא",
    speak: "דיבור והגייה",
    spelling: "איות",
    vocabulary: "אוצר מילים"
  },
  skillsTestTryAgain: "אפשר לנסות שוב עכשיו או לחזור לחידונים הקודמים ברמה הזאת ולתרגל עוד קצת.",
  skip: "דילוג",
  sorryThisLinkIsNoLongerValid: "לצערנו, קישור זה אינו תקף עוד.",
  sortBy: "מיון לפי:",
  speakNow: "נא לבטא את המילה",
  speak_tip: "טיפ בדיבור",
  spelling_tip: "טיפ באיות",
  stars: "כוכבים",
  start: "התחלה",
  startLevelTest: "התחלת המבחן",
  startNextQuiz: "לחידון הבא",
  startQuiz: "התחלת חידון",
  startTheNextLevel: "מעבר לרמה הבאה",
  startTheNextQuiz: "לחידון הבא",
  startingReward: "פרס להתחלה",
  state: "מחוז",
  studentActivity: "פעילות",
  studentInstructions: "ההוראות לתלמידים",
  studentLevels: "רמות",
  studentName: "שם",
  studentReport: "דו\"ח על תלמיד",
  students: "תלמידים",
  studentsAreNotPermittedToAdvanceToTheNextLevel: "התלמידים אינם יכולים להתקדם לרמה הבאה. אם החלטת לשנות את רמת התלמיד לפי הביצועים שלו, אפשר לעשות זאת באמצעות עריכה ידנית של הרמה בכל תחום לימוד בפרופיל של התלמיד.",
  study_language: "שפת לימוד",
  subject: "נושא",
  submit: "שליחה",
  suggested_languages: "שפות לבחירה",
  sunday: "יום ראשון",
  sundayShort: "א'",
  synonyms: "מילים נרדפות",
  tapToSpeak: "לחצו על סימן המיקרופון כדי לדבר",
  tapToTranslate: "יש להקיש כדי לתרגם",
  teacherEndLevelTest: "לתשומת ליבך, חידוני הדיבור והאיות יופיעו עבורך ועבור התלמידים שלך ברמה 1.\nלכן הרמה הכוללת תיראה מעט יותר נמוכה. יש לך אפשרות להתאים באופן ידני את הרמה של כל תלמיד ותלמידה בכל תחום לימוד בלחיצה על הפרופיל שלהם ברשימת 'התלמידים שלי'. כמורה, יש לך גישה לכל החידונים בכל הרמות.",
  teacherInstructions: "ההוראות למורה",
  teacherLetsBeginn: "נתחיל!",
  teacherName: "שם המורה",
  teachers: "מורות ומורים",
  teachersThatHaveRegistered: "מורים שנרשמו",
  termsAndConditions: "תנאי השימוש",
  testYourEnglish: "נבדוק את רמת האנגלית שלך",
  thankYou: "תודה!",
  thatsTheWay: "יפה מאוד!",
  thats_the_way: "יפה מאוד!",
  theGamesIn: "החידונים ברמה",
  theWordWasntPronouncedCorrectly: "לא ביטאת נכון את המילה או הביטוי",
  theWordsWerentPronouncedCorrectly: "לא ביטאת נכון את המילה או הביטוי",
  thereAre: "יש",
  thereIs: "יש",
  thisStudentHasNot: "התלמיד או התלמידה עוד לא השלימו את מבחן הרמה. לאחר השלמת המבחן תהיה לך אפשרות לראות את התקדמותם.",
  thisTestIsDesignedToAssessYourStudentsInTheirGeneralEnglishLevel: "כאשר התלמידים ישלימו את כל החידונים בתחום מסוים ברמה שלהם, הם יוכלו לעבור מבחן על כל מה שלמדו. אפשר לבחור מראש את התאריך של המבחן הזה למטה. ",
  thisTestWillHelpYouSeeHowMuchEnglishYouHaveLearnedSoFar: "מבחן זה יעזור לך לראות מה למדת באנגלית עד עתה. הוא כולל דקדוק, אוצר מילים, קריאה, הקשבה ודיבור. לאחר המבחן אפשר להמשיך לשחק בחידונים וללמוד.",
  thisWillHelpYouUnderstandTheMaterialBetter: "זה יעזור לך להבין טוב יותר את חומר הלימוד",
  thursday: "יום חמישי",
  thursdayShort: "ה'",
  thwWordYouEntered: "לא נמצא תרגום מילוני. אפשר לנסות מילה אחרת.",
  timeIsUp: "נגמר הזמן!",
  tip: "טיפ!",
  tipsAndTricks: "טיפ",
  to: "אל",
  toImproveYourVocabulary: "כדי לשפר את אוצר המילים שלך,",
  toJoin: "הזמנה להצטרף לכיתה",
  toNextQuestion: "לשאלה הבאה",
  toViewItsOverallLearningProgress: "כדי לראות את ההתקדמות הכללית שלה",
  toViewTheirIndividualProgress: "כדי לראות התקדמות אישית",
  toViewTheirOverallProgress: "כדי לראות את התקדמותם הכוללת",
  todayQuestion: "מילת היום",
  topics: "נושאים",
  totalCoins: "מטבעות",
  totalHintsUsed: "מספר הרמזים",
  totalQuizzes: "כל החידונים",
  totalWords: "סך כל המילים",
  trasnlateAWordOrPhrase: "תרגמו מילה או משפט באנגלית או ב{{nativeLanguage}}...",
  tries: "ניסיונות",
  tryAgain: "חזרה על החידון",
  tryAgainBtn: "ניסיון חוזר",
  tryAgainLater: "יש לנסות שוב בהמשך",
  tryAgainOneMore: "לנסות עוד פעם אחת או להמשיך הלאה",
  tryAgainOrKeepGoing: "לנסות שוב או להמשיך",
  tryBetter: "לא להתייאש, להמשיך לתרגל!",
  tuesday: "יום שלישי",
  tuesdayShort: "ג'",
  tutorBack: "< חזרה",
  tutorBannerTitle: "הגיע הזמן לשדרג את תהליך הלמידה שלך! לפי הביצועים שלך בחידונים, יש לנו כמה הצעות עבורך כדי לשפר את הידע שלך באנגלית ולאתגר אותך להצליח.",
  tutorChalangeTitle: "מומלץ לקחת הפסקות קצרות מהלמידה כדי לשפר את המיקוד והזיכרון.",
  tutorChallenge: "אני רוצה אתגר",
  tutorChooseAnswer: "בחרו בתשובה המתאימה ביותר למילוי המשפט:",
  tutorClickToPractice: "יש ללחוץ כאן כדי לתרגל!",
  tutorContinue: "המשך ללא תרגול",
  tutorContinueQuiz: "המשך החידון",
  tutorCorrectAnswer: "נא להראות לי את התשובה הנכונה",
  tutorCorrectAnswerIs: "התשובה הנכונה היא \"{{answer}}\".",
  tutorCorrectAnswerIsShort: "התשובה הנכונה היא <strong> {{answer}} </strong>",
  tutorCorrectBody: "קלטת את זה!",
  tutorCorrectSentence: "התשובה הנכונה היא...",
  tutorCorrectTitle: "נכון!",
  tutorEmptyDashboard: "אופס! אין כאן משהו מעניין עדיין... הצוות שלנו עובד על תוכן נוסף!",
  tutorEndChatOption: "לסיים את הצ'אט ולחזור",
  tutorExampleOption: "אשמח לעוד דוגמה",
  tutorExerciseOption: "בואו נתרגל",
  tutorExitNo: "המשך",
  tutorExitText: "לתשומת ליבך, סגירת החלון של המורה הפרטי תמחק את הצ'אט הנוכחי. אל דאגה, אפשר תמיד להתחיל צ'אט חדש.",
  tutorExitTitle: "התרגול בעיצומו",
  tutorExitYes: "סיום התרגול",
  tutorExplainMore: "ללמוד עם המורה הפרטי",
  tutorFeedback: "רוצה לתת משוב?",
  tutorGetSession: "מזמינים אותך לפעילות מותאמת אישית",
  tutorGetSessionTitle: "מזמינים אותך לפעילות מותאמת אישית כדי להקל על הלמידה שלך בהמשך",
  tutorGiveExample: "תן לי עוד דוגמה",
  tutorGotIt: "הבנתי",
  tutorHowHelp: "איך אפשר לעזור לך?",
  tutorInCorrectBody: "התשובה שלך, \"{{answer}}\" היא שגויה.",
  tutorInCorrectTitle: "אופס. לא נכון.",
  tutorKeepGoing: "התקדמת נהדר! מומלץ לקחת הפסקות קצרות מהלמידה כדי לשפר את המיקוד והזיכרון.",
  tutorLetMeExplain: "אתחיל להסביר...",
  tutorLetsPractice: "רוצה לתרגל לפני שנמשיך?",
  tutorLetsPracticeOption: "בואו נתרגל",
  tutorLetsStart: "מתחילים!",
  tutorMore: "מומלץ לתרגל",
  tutorMoreExplanation: "עוד הסבר",
  tutorMoreHelp: "עוד עזרה",
  tutorMoreTips: "עוד טיפים",
  tutorNeedHint: "אשמח לקבל רמז",
  tutorNewtag: "חדש",
  tutorNoAndContinue: "לא, ולהמשיך",
  tutorNoMoreHelp: "לא, אשמח לעוד עזרה",
  tutorNotification: "הודעות במהלך החידון",
  tutorNotificationBack: "חזרה",
  tutorNotificationBody: "לבטל את האפשרות לקבל הסברים אחרי כל תשובה לא נכונה עד לסיום החידון?",
  tutorNotificationMuted: "ההודעות במהלך החידון בוטלו עד לסיום החידון",
  tutorNotificationOff: "כן, לבטל",
  tutorPractice: "לתרגל כדי להשתכלל",
  tutorPracticeIsRecommended: "מומלץ לתרגל",
  tutorPracticeWithMe: "לתרגל איתי",
  tutorPracticeWithMeTitle: "אפשר לתרגל איתי כדי לשפר את המיומנויות שלך בדקדוק.",
  tutorPrivate: "מורה פרטי",
  tutorPrivateTutorTip: "טיפ מהמורה הפרטי!",
  tutorShifting: "אנחנו מכינים את החידון {{quizName}}. רק עוד רגע...",
  tutorSkill: "תחום הלימוד",
  tutorStartedAt: "הצ'אט התחיל ב-{{timeStart}}",
  tutorTryBetterTitle: "אפשר להשתפר בפעם הבאה",
  tutorTurnOff: "לכבות",
  tutorUser: "משתמש",
  tutorWasClear: "זה היה ברור?",
  tutorWasHelpful: "האם זה עזר לך?",
  tutorWelcomeTitle: "היי, כאן המורה הפרטי שלך",
  tutorYesAndContinue: "כן, ולהמשיך",
  tutorYesGoBack: "כן, אפשר לחזור לחידון",
  tutor_quizzes_not_taken: "בואו נתרגל עוד!",
  tutor_quizzes_taken: "בואו נהפוך נקודות חולשה לנקודות חוזק",
  understandingYourEnglishLevel: "אנו מתחילים להבין את רמת האנגלית שלך.",
  unfortunately: "למרבה הצער לא עברת, לכן לא קיבלת מטבעות על החידון הזה.",
  university: "אוניברסיטה",
  update: "עדכון",
  uponRegistrationStudentsWillReceiveAnInitialLevelTest: "לאחר ההרשמה לאתר, יעברו התלמידים מבחן רמה ראשוני. עם סיום המבחן, מסלולי הלמידה שלהם ייקבעו בהתאם לביצועים שלהם בכל תחום למידה. אם אפשרות זו תבוטל, התלמידים שלך יתחילו ברמה 1 ללא מבחן רמה ראשוני.",
  useTheCoins: "אפשר להשתמש במטבעות שהרווחת כדי לקבל עזרה",
  userStreak: {
    subTitle: "מומלץ לתרגל כל יום כדי להיות מומחים",
    title: "כדאי לשמור על הרצף!"
  },
  username: "שם משתמש",
  usingYourMicrophone: "יש לדבר לאט וברור אל המיקרופון, ולוודא שאין רעשי רקע.",
  validation: {
    code_required: "קוד נדרש",
    confirm_password_required: "יש לאשר את הסיסמה",
    email_required: "יש להכניס כתובת מייל",
    password_required: "יש להזין סיסמה",
    username_required: "יש להזין שם משתמש"
  },
  validationCodeInvalid: "קוד האימות אינו תקין",
  verificationCode: "קוד אימות",
  verificationCodeSent: "קוד אימות נשלח אל",
  viewAll: "הצגת הכול",
  viewAnswerChoices: "צפייה באפשרויות התשובה",
  viewDetails: "צפייה בפרטים",
  viewProfile: "צפייה בפרופיל",
  vocabularyMistakes: "מילים שטעיתי בהן",
  vocabularyPractice: "תרגול אוצר המילים",
  vocabulary_tip: "טיפ באוצר מילים",
  weAreSorry: "סליחה",
  weCouldNotProduce: "לא יכולנו להפיק את הדו\"ח כרגע.",
  weRecommendThatYouGoBackToYourPreviousGames: "אנו ממליצים לך לחזור לחידונים הקודמים ולעשות אותם שוב.",
  weStillDontHaveAll: "עדיין חסר לנו כל המידע הדרוש, או שיש תקלה זמנית.",
  weWantYourFeedback: "היי {{firstname}}, נשמח לקבל ממך משוב!",
  we_want_to_know: "אנחנו רוצים לדעת את הרמה שלך",
  wednesday: "יום רביעי",
  wednesdayShort: "ד'",
  welcome: "ברוך בואך",
  welcomeBack: "טוב שחזרת",
  welcomeConfetti: "ברוך בואך",
  welcomeTo: "ברוך בואך אל",
  wellDone: "כל הכבוד",
  whatDidYouLearnAtThisLevel: "מה למדת ברמה הזאת?",
  whenStudentsWillHaveCompletedAParticularSkill: "כאשר התלמידים ישלימו את כל החידונים בתחום מסוים ברמה המיועדת להם, הם ייבחנו על מה שלמדו. עליהם לקבל ציון של 80% לפחות כדי לעבור לרמה הבאה. תלמידים שלא יעברו את המבחן בציון זה, יתבקשו להיבחן שוב.",
  whenYourStudentsReceiveAPassingGrade: "כאשר התלמידים שלך יעברו בהצלחה את מבחן ההערכה, הם יוכלו להתקדם לרמה הבאה בכל אחד מהתחומים ולהמשיך ללמוד בקצב אישי. אם אפשרות זו תבוטל, התלמידים לא יוכלו להתקדם לרמה הבאה.",
  with_a_score_of: "בציון",
  wordOfTheDay: "מילת היום",
  words: "ללמוד אנגלית",
  wordsDictionary: "מילים",
  wordsList: "רשימת מילים",
  workHard: "עוד קצת מאמץ, ואפשר לזכות בכל הכוכבים.",
  work_hard_to_get_all_stars: "עוד קצת מאמץ, ואפשר לזכות בכל הכוכבים.",
  wow: "איזה יופי",
  wow_you_got_a_score_of: "איזה יופי, קיבלת ציון {{score}}",
  writeClassNameHere: "נא לכתוב את שם הכיתה",
  writeHere: "לכתוב כאן",
  wrongAnswer: "תשובה שגויה!",
  youAlmostThere: "עוד קצת וסיימת...",
  youAreOnYourWay: "ממשיכים בחידון!",
  youCanCreateMore: "אפשר ליצור יותר מכיתה אחת",
  youCanPractice: "אפשר לשחק בחידון או לתרגל את רשימת המילים בעזרת כרטיסיות.",
  youCanTryAssessment: "עכשיו יש לך אפשרות לנסות את מבדק הסיום לרמה {{level}} שתלמידיך ייבחנו בו לפני שהם יוכלו להתקדם לרמה הבאה.",
  youCompletedTheTest: "סיימתם את המבחן",
  youDidNotPass: "לא עברת",
  youGot: "יש לך",
  youMayNowShare: "כעת אפשר לשתף את הקישור עם ",
  youPassedAll: "עברת את כל",
  youPassedAllTheGamesIn: "עברת בהצלחה את כל החידונים בנושא",
  youPassedTheQuiz: "עברת את החידון בציון",
  youPracticed: "תרגלת ולמדת",
  youStill: "עדיין לא עברת בהצלחה את החידון, לכן לא קיבלת עוד מטבעות או כוכבים.",
  youWillLose: "אחרת לא יישמרו לך",
  you_can_now_practice_level: "עכשיו אפשר לתרגל ברמה {{level}}",
  you_did_it: "הצלחת!",
  you_got_a_score_of: "הציון שלך: {{score}}",
  you_passed_assessment: "עברת את מבדק הסיום",
  you_passed_quiz: "עברת את החידון בהצלחה",
  your: "שלך",
  yourChangesWillAffectTheGeneralLevel: "השינויים שלך ישפיעו על הרמה הכללית",
  yourClasses: "הכיתות שלך",
  yourMessageHasBeenSentSuccessfully: "ההודעה נשלחה בהצלחה. תודה שהקדשת זמן כדי לפנות אלינו!",
  yourMessageHere: "תוכן ההודעה",
  yourPasswordHere: "הסיסמה שלך",
  yourPhoneHere: "מספר הטלפון שלך",
  yourSchoolHasBeenCreated: "בית הספר נוצר בהצלחה",
  yourStartingLevel: "הרמה שלך היא",
  yourStreak: "רצף התרגול שלך",
  yourStudentQuiz: "זהו המבחן שהתלמידים שלך יעברו אחרי שהם יתחברו בפעם הראשונה. אפשר לנסות אותו בעצמך עכשיו או מאוחר יותר.",
  yourStudentsHaveNotJoined: "התלמידים שלך עוד לא הצטרפו לכיתה",
  yourStudentsHaveNotJoinedYet: "התלמידים שלך עוד לא <br /> הצטרפו לכיתה",
  yourStudentsInThisClassWillNotTakeTheInitialLevelTest: "התלמידים בכיתה זו לא יעברו את מבחן הרמה הראשוני אחרי ההרשמה. כולם יתחילו ברמה 1 בכל תחומי הלימוד.",
  yourStudentsWillNotReceiveAFinalExam: "התלמידים שלך לא יקבלו בחינה סופית.",
  yourSubjectHere: "נושא הפנייה"
};
