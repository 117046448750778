import config from '@/config';
import { CognitoService } from '@/types/services/cognito';
import 'cross-fetch/polyfill';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

import { authActions } from '@/store/reducers/auth';

export const cognitoService: CognitoService = {
  getUserAttributes: async (user: AmazonCognitoIdentity.CognitoUser) => {
    return new Promise((resolve, reject) => {
      user.getUserAttributes((error, attributes) => {
        resolve(attributes ?? []);
      });
    });
  },
  refreshUserSession: async () => {
    const poolData = {
      UserPoolId: config.awsUserPoolId,
      ClientId: config.awsCognitoClientId,
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const refreshToken = localStorage.getItem('refresh_token');

    const cognitoRefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
      RefreshToken: refreshToken ?? '',
    });

    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      throw new Error('No current user found');
    }

    return new Promise<string>((resolve, reject) => {
      cognitoUser.refreshSession(
        cognitoRefreshToken,
        (err, session: AmazonCognitoIdentity.CognitoUserSession | null) => {
          if (err) {
            console.error('Error refreshing session:', err);
            reject(err);
            return;
          }

          if (!session) {
            console.error('Session is null');
            reject(new Error('Session is null'));
            return;
          }

          const token = session.getAccessToken().getJwtToken();
          const refreshTokenToken = session.getRefreshToken().getToken();

          localStorage.setItem('token', token);
          localStorage.setItem('refresh_token', refreshTokenToken);
          resolve(token);
        }
      );
    });
  },
  checkCognitoSession: async () => {
    const poolData = {
      UserPoolId: config.awsUserPoolId,
      ClientId: config.awsCognitoClientId,
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    return new Promise((resolve, reject) => {
      cognitoUser?.getSession(async (err: any, session: any) => {
        if (err) {
          return reject(false);
        }

        if (session.isValid()) {
          const attributes = await cognitoService.getUserAttributes(
            cognitoUser
          );

          const nativeLanguage =
            cognitoService.getNativeLanguageAttribute(attributes);

          const teacherLevel =
            cognitoService.getTeacherLevelAttribute(attributes);

          resolve({
            teacherLevel: Number(teacherLevel?.Value ?? 1),
            nativeLanguage: nativeLanguage?.Value ?? 'pt',
          });
        } else {
          return reject(false);
        }
      });
    });
  },
  setTeacherLevel: async (level: number) => {
    const poolData = {
      UserPoolId: config.awsUserPoolId,
      ClientId: config.awsCognitoClientId,
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    return new Promise((resolve, reject) => {
      cognitoUser?.getSession((err: any, session: any) => {
        if (err) {
          return reject(false);
        }

        if (session.isValid()) {
          cognitoUser?.updateAttributes(
            [{ Name: 'custom:teacherLevel', Value: level.toString() }],
            (err, results) => {
              if (err) {
                return reject(false);
              }

              resolve(true);
            }
          );
        } else {
          return reject(false);
        }
      });
    });
  },
  getNativeLanguageAttribute: (
    attributes: AmazonCognitoIdentity.CognitoUserAttribute[]
  ) => {
    return attributes.find((attr) => attr.Name === 'custom:nativeLanguage');
  },
  getTeacherLevelAttribute: (
    attributes: AmazonCognitoIdentity.CognitoUserAttribute[]
  ) => {
    return attributes.find((attr) => attr.Name === 'custom:teacherLevel');
  },
  authenticate: async (username: string, password: string) => {
    const authenticationData = {
      Username: username,
      Password: password,
    };

    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

    const poolData = {
      UserPoolId: config.awsUserPoolId,
      ClientId: config.awsCognitoClientId,
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (session) => {
          const token = session.getAccessToken().getJwtToken();
          const refreshToken = session.getRefreshToken().getToken();
          localStorage.setItem('refresh_token', refreshToken);
          const userId = session.getAccessToken().payload['sub'];
          const attributes = await cognitoService.getUserAttributes(
            cognitoUser
          );

          const nativeLanguage =
            cognitoService.getNativeLanguageAttribute(attributes);

          resolve({ token, userId, nativeLanguage: nativeLanguage });
        },
        newPasswordRequired: function (userAttributes, requiredAttributes) {
          resolve({
            newPasswordRequired: true,
            requiredAttributes,
            cognitoUser,
          });
          // cognitoUser.completeNewPasswordChallenge(
          //   password,
          //   requiredAttributes,
          //   {
          //     onSuccess: async (session) => {
          //       const token = session.getAccessToken().getJwtToken();
          //       const userId = session.getAccessToken().payload['sub'];
          //       const attributes = await cognitoService.getUserAttributes(
          //         cognitoUser
          //       );
          //       const nativeLanguage =
          //         cognitoService.getNativeLanguageAttribute(attributes);

          //       resolve({ token, userId, nativeLanguage: nativeLanguage });
          //     },
          //     onFailure: () => {},
          //   }
          // );
        },
        onFailure: function (err: { message: any; code: any }) {
          reject(err.code);
        },
      });
    });
  },

  createAccount: async (name, email, password, nativeLanguageCode, role) => {
    const poolData = {
      UserPoolId: config.awsUserPoolId,
      ClientId: config.awsCognitoClientId,
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute({
      Name: 'email',
      Value: email,
    });

    const attributeName = new AmazonCognitoIdentity.CognitoUserAttribute({
      Name: 'name',
      Value: name,
    });

    const attributeNativeLanguage =
      new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: 'custom:nativeLanguage',
        Value: nativeLanguageCode,
      });

    const attributeRole = new AmazonCognitoIdentity.CognitoUserAttribute({
      Name: 'custom:role',
      Value: role,
    });

    return new Promise((resolve, reject) => {
      userPool.signUp(
        email,
        password,
        [attributeEmail, attributeName, attributeNativeLanguage, attributeRole],
        [],
        (err, result) => {
          if (err) {
            return reject(err.message);
          }

          return resolve(result?.userSub);
        }
      );
    });
  },
  verifyAccount: function (email, verificationCode) {
    const poolData = {
      UserPoolId: config.awsUserPoolId,
      ClientId: config.awsCognitoClientId,
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(
        verificationCode,
        true,
        function (err, result) {
          if (err) {
            return reject(err);
          }

          return resolve(result);
        }
      );
    });
  },
  confirmForgotPassword: function (email, verificationCode, newPassword) {
    const poolData = {
      UserPoolId: config.awsUserPoolId,
      ClientId: config.awsCognitoClientId,
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: function () {
          resolve(true);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  },
  forgotPassword: function (email) {
    const poolData = {
      UserPoolId: config.awsUserPoolId,
      ClientId: config.awsCognitoClientId,
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          resolve(true);
        },
        onFailure: function (err) {
          reject(err);
        },
        // inputVerificationCode() {
        //   // this is optional, and likely won't be implemented as in AWS's example (i.e, prompt to get info)
        //   var verificationCode = prompt('Please input verification code ', '');
        //   var newPassword = prompt('Enter new password ', '');
        //   cognitoUser.confirmPassword(
        //     verificationCode as string,
        //     newPassword as string,
        //     this
        //   );
        // },
      });
    });
  },
  resendVerificationCode: function (email) {
    const poolData = {
      UserPoolId: config.awsUserPoolId,
      ClientId: config.awsCognitoClientId,
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode(function (err: any, result) {
        if (err) {
          return reject(err.code);
        }

        return resolve(result);
      });
    });
  },
  forceChangeNewPassword: async (cognitoUser, requiredAttributes, password) => {
    return new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(password, requiredAttributes, {
        onSuccess: async (session) => {
          const token = session.getAccessToken().getJwtToken();
          const userId = session.getAccessToken().payload['sub'];
          const attributes = await cognitoService.getUserAttributes(
            cognitoUser
          );

          const nativeLanguage =
            cognitoService.getNativeLanguageAttribute(attributes);

          resolve({ token, userId, nativeLanguage: nativeLanguage });
        },
        onFailure: () => {},
      });
    });
  },
  updatePassword: async (username, password, newPassword) => {
    const poolData = {
      UserPoolId: config.awsUserPoolId,
      ClientId: config.awsCognitoClientId,
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      const authenticationData = {
        Username: username,
        Password: password,
      };

      const authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

      return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: function () {
            cognitoUser.changePassword(password, newPassword, (error: any) => {
              if (!error) {
                resolve(true);
              } else if (error.message) {
                reject(error.code);
              }
            });
          },
          onFailure: function (error: any) {
            reject(error.code);
          },
        });
      });
    }
  },
};
