import { GameTypeProps, PronunciationProps } from '@/types/game';
import classNames from './Pronunciation.module.scss';
import SVG from 'react-inlinesvg';
import { useEffect, useRef, useState } from 'react';
import classes from 'classnames';
import soundPlay from '@/assets/svg/speaker.svg';
import soundPlayDisabled from '@/assets/svg/speaker0.2.svg.svg';
import PronunciationMicrophone from './PronunciationMicrophone/PronunciationMicrophone';
import UseScreenSize from '@/hooks/UseScreenSize';
import UseLocalLang from '@/hooks/UseLocalLang';
import { gameSelector } from '@/store/reducers/game';
import { useAppSelector } from '@/store';
import commonUtils from '@/utils/common';
import { studyingLanguageSelector } from '@/store/reducers/auth';
import GameUtils from '@/utils/gameUtils';

const Pronunciation = ({
  currentQuestion,
  pickedAnswer,
  allowRetry,
  emitOnRetry,
  emitOnAnswer,
  emitOnNextQuestion,
}: GameTypeProps & PronunciationProps) => {
  const { isDesktop } = UseScreenSize();
  const localLang = UseLocalLang();
  const { selectedQuiz, revealTranslation, isFinalAssessment } =
    useAppSelector(gameSelector);
  const [isListening, setIsListening] = useState(false);
  const studyingLanguage = useAppSelector(studyingLanguageSelector);

  const onPlaySound = () => {
    if (currentQuestion.targetWordSound) {
      GameUtils.playSoundFromUrl(currentQuestion.targetWordSound);
    }
  };
  const checkIfIsListening = (isListening: boolean) => {
    setIsListening(isListening);
  };
  useEffect(() => {}, [revealTranslation]);
  return (
    <div className={classNames.pronunciation}>
      {isDesktop && selectedQuiz && (
        <h2 className={classNames.instructions}>
          {commonUtils.getQuizInstructions(selectedQuiz, localLang)}
        </h2>
      )}
      <div className={classNames.content}>
        <div
          className={classNames.top}
          style={{
            direction:
              (studyingLanguage?.direction as React.CSSProperties['direction']) ??
              'ltr',
          }}
        >
          <h2>{currentQuestion.question}</h2>
          {currentQuestion.targetWordSound && (
            <>
              <button
                disabled={isListening}
                className={classes(classNames.soundBtn, {
                  [classNames.soundBtnDisabled]: isListening,
                })}
                onClick={onPlaySound}
              >
                <SVG className={classNames.svg} src={soundPlay} />
              </button>
            </>
          )}
        </div>
        {revealTranslation && (
          <div>{currentQuestion.targetWordTranslation ?? ''}</div>
        )}
        <PronunciationMicrophone
          currentQuestion={currentQuestion}
          pickedAnswer={pickedAnswer}
          allowRetry={isFinalAssessment ? false : allowRetry}
          checkIfIsListening={checkIfIsListening}
          emitOnRetry={emitOnRetry}
          emitOnAnswer={emitOnAnswer}
          emitOnNextQuestion={emitOnNextQuestion}
        />
      </div>
    </div>
  );
};

export default Pronunciation;
